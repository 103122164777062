import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { IoEyeOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import NoAdminImage from '../images/avatar/avatar.png';
import { useAuth } from "../component/AuthContext";
import { FaAngleRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import LoadingGif from '../images/avatar/aino_loading.gif';
import { Alert } from "react-bootstrap";

const Retailer = () => {
  const navigate = useNavigate();
  const { retailer = [], getAllRetailer } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchRetailers = async () => {
      setLoading(true); // Set loading to true before fetching
      await getAllRetailer(currentPage);
      setLoading(false); // Set loading to false after fetching
    };

    fetchRetailers();
  }, [currentPage ]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNavigate = (id) => {
    if (id) {
      navigate(`/dashboard/userdetails/${id}`);
    } else {
      Alert("User ID is missing or undefined.");
    }
  };

  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3>Below is a List of All Aino Retailers. Please Note that Each Page Consist of 20 Retailer Each.</h3>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        
                      </ul>
                    </div>
                    {loading ? (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '150px' }}>
                        <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                        <p>All Retailer Loading...</p>
                      </div>
                    ) : (
                      <div className="wg-box">
                        <div className="wg-table table-all-user">
                          <ul className="table-title flex gap20 mb-14">
                            <li><div className="body-title" >Retailer FullName</div></li>
                            <li><div className="body-title" >Email</div></li>
                            <li><div className="body-title" >Verify Status</div></li>
                            <li><div className="body-title" >Gender</div></li>
                            <li><div className="body-title" >Retailer Number</div></li>
                            <li><div className="body-title" >Retailer Address</div></li>
                            <li><div className="body-title">View More</div></li>
                          </ul>
                          <ul className="flex flex-column">
                            {Array.isArray(retailer) && retailer.length > 0 ? (
                                retailer.map((users, index) => (
                                <li className="user-item gap14" key={users._id}>
                                  <div className="">{index + 1}</div>
                                  <div className="image no-bg">
                                    <img src={ users?.profileImg || NoAdminImage} alt="" className="" />
                                  </div>
                                  <div className="flex items-center justify-between gap20 flex-grow">
                                    <div className="name">
                                      <Link to="" className="body-title-2">
                                        {users?.firstName || "N/A"} {users.lastName || " "}
                                      </Link>
                                    </div>
                                    <div className="body-text">{users?.email || ""}</div>
                                    <div className={users.isVerified ? "block-available" : "block-pending"}>
                                      {users?.isVerified ? "true" : "false"}
                                    </div>
                                    <div className="body-text">{users?.gender || ""}</div>
                                    <div className="body-text">{users?.phoneNumber || ""}</div>
                                    <div className="body-text">
                                      {users?.addresses.length > 0 ? `${users.addresses[0].streetAdd}, ${users.addresses[0].city}, ${users.addresses[0].state}` : "No Address"}
                                    </div>
                                    <div className="list-icon-function">
                                      <div className="item eye">
                                        <IoEyeOutline color="blue" onClick={() => {  handleNavigate(users._id)}} />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                <img src={NoAdminImage} alt="No Users Found yet" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                                <h2>No Retailers Found yet</h2>
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="divider"></div>
                        <div className="flex items-center justify-between flex-wrap gap10">
                          <div className="wg-pagination-wrap"></div>
                          <ul className="wg-pagination">
                            <li>
                              <Link to="#" onClick={handlePreviousPage} className="pagination-prev" style={{ pointerEvents: currentPage === 1 ? 'none' : 'auto', opacity: currentPage === 1 ? 0.5 : 1 }}>
                                <FaChevronLeft />
                              </Link>
                            </li>
                            <li>
                              <span>{currentPage}</span>
                            </li>
                            <li>
                              <Link to="#" onClick={handleNextPage} className="pagination-next" style={{ pointerEvents: currentPage === totalPages ? 'none' : 'auto', opacity: currentPage === totalPages ? 0.5 : 1 }}>
                                <FaChevronRight />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">
                    Copyright © 2024 Aino Tech Global.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Retailer;
