import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { IoEyeOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import NoAdminImage from '../images/avatar/avatar.png';
import { useAuth } from "../component/AuthContext";
import { FaAngleRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import LoadingGif from '../images/avatar/aino_loading.gif';

const Influencer = () => {
  const navigate = useNavigate();
  const { influencer = [], getAllInfluencer } = useAuth();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true); 
  const userPerPage = 10; 

  useEffect(() => {
    const fetchInfluencer = async () => {
      setLoading(true); 
      await getAllInfluencer(currentPage);
      setLoading(false); 
    };

    fetchInfluencer();
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const convertExpirationTime = (expirationDate) => {
    const date = new Date(expirationDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    return date.toLocaleString('en-US', options);
  };

  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3>All Aino Ambassador / Influencer </h3>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        <li>
                          <Link to="/" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Dashboard</div>
                          </Link>
                        </li>
                        <li><FaAngleRight /></li>
                        <li>
                          <Link to="/dashboard/influencer" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Aino Ambassador / Influencer</div>
                          </Link>
                        </li>
                        <li><FaAngleRight /></li>
                        <li><div className="text-tiny">All Aino Ambassador / Influencer </div></li>
                      </ul>
                    </div>
                    {loading ? (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '150px' }}>
                        <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                        <p>All Aino Ambassador / Influencer Loading...</p>
                      </div>
                    ) : (
                      <div className="wg-box">
                        <div className="wg-table table-all-user">
                          <ul className="table-title flex gap20 mb-14">
                            <li><div className="body-title" >Brand Name</div></li>
                            <li><div className="body-title" >Email</div></li>
                            <li><div className="body-title" >Wallet Balance</div></li>
                            <li><div className="body-title" >Created At</div></li>
                            <li><div className="body-title"></div></li>
                            
                          </ul>
                          <ul className="flex flex-column">
                            {Array.isArray(influencer) && influencer.length > 0 ? (
                              influencer.map((influencers, index) => (
                                <li className="user-item gap14" key={influencers._id}>
                                  <div className="">{index + 1}</div>
                                  
                                  <div className="flex items-center justify-between gap20 flex-grow">
                                    <div className="name">
                                      <Link to="" className="body-title-2">
                                        {influencers?.brandName || "Unknown"} 
                                      </Link>
                                    </div>
                                    <div className="body-text">{influencers?.email || ""}</div>
                                    <div className="body-text">{influencers?.wallet || ""}</div>
                                    <div className="body-text">{convertExpirationTime(influencers.createdAt)} </div>
                                    <div className="list-icon-function">
                                      <div className="item eye">
                                        <IoEyeOutline color="blue" />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                <img src={NoAdminImage} alt="No Users Found yet" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                                <h2>No Aino Ambassador / Influencer Found yet</h2>
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="divider"></div>
                        <div className="flex items-center justify-between flex-wrap gap10">
                          <div className="wg-pagination-wrap"></div>
                          <ul className="wg-pagination">
                            <li>
                              <Link to="#" onClick={handlePreviousPage} className="pagination-prev" style={{ pointerEvents: currentPage === 1 ? 'none' : 'auto', opacity: currentPage === 1 ? 0.5 : 1 }}>
                                <FaChevronLeft />
                              </Link>
                            </li>
                            <li>
                              <span>{currentPage}</span>
                            </li>
                            <li>
                              <Link to="#" onClick={handleNextPage} className="pagination-next" style={{ pointerEvents: currentPage === totalPages ? 'none' : 'auto', opacity: currentPage === totalPages ? 0.5 : 1 }}>
                                <FaChevronRight />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">
                    Copyright © 2024 Aino Tech Global.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Influencer;
