import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../../merchant/component/Siderbar";
import Header from "../../merchant/component/Header";
// import PrivateRoute from '../component/PrivateRoute';
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";


const MerchantLayout = () => {
  return (
    <div className="container-fluid">
        <div className="row">
            <aside className="layout-wrap">
                <SideBar />
            </aside>
            <div className="section-content-right">
                <header className="">
                    <Header />
                </header>
                <main className="">
                    {/* <PrivateRoute> */}
                    <Outlet />
                    {/* </PrivateRoute> */}
                </main>
            </div>
        </div>
    </div>
  );
};

export default MerchantLayout;
