import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import vendor from "../../images/avatar/vendor.jpg";
import Utlilty from "../../utils/func";
import { useAuth } from "../../merchant/component/AuthContext";
import NoAdminImage from '../../images/avatar/order.png';

const MerchantDashboard = () => {
    const utility = new Utlilty();
    const { product, order, sale, profile } = useAuth();
        return (
        <> 
            <section id="main" className="main">
                <div className="pagetitle">
                    <h1>Dashboard</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="">Dashbaord</Link>
                            </li>
                            <li className="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </nav>
                </div>
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-xxl-3 col-md-6">
                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <h5 className="card-title"> Total Sale Amount </h5>
                                    <div className="d-flex align-items-center">
                                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                            <i className="bi bi-cart"></i>
                                        </div>
                                        <div className="ps-3">
                                            <h6> &#x20A6;{sale?.totalSalesAmount}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                            <div className="card info-card revenue-card">
                                <div className="card-body">
                                    <h5 className="card-title"> Total Products </h5>
                                    <div className="d-flex align-items-center">
                                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                            <i className="bi bi-currency-dollar"></i>
                                        </div>
                                        <div className="ps-3">
                                            <h6> {sale?.totalProducts}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-md-6">
                            <div className="card info-card revenue-card">
                                <div className="card-body">
                                    <h5 className="card-title"> Wallet Balance</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                            <i className="bi bi-currency-dollar"></i>
                                        </div>
                                        <div className="ps-3">
                                            <h6>&#x20A6;{utility.profile?.wallet}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Recent prodducts Upload --> */}
                    <div className="col-12">
                        <div className="card top-selling overflow-auto">
                            <div className="card-body pb-0">
                            <h5 className="card-title">Recent Products Upload</h5>
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">S/N</th>
                                            <th scope="col">Product Name</th>
                                            <th scope="col">Products Description</th>
                                            <th scope="col">Merchant Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Product Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(product) && product.map((item, index) => (
                                            <tr key={index} style={{ fontSize: '1.5rem' }}>
                                                <th scope="row" style={{ marginRight: '20px' }}>
                                                    {index + 1}
                                                    <span>
                                                        <img src={item.images[0]} alt="" style={{ width: '70px', height: '70px', objectFit: 'cover' }} />
                                                    </span>
                                                </th>
                                                <td>{item.name}</td>
                                                <td>{utility.limitText(item.description, 4)}</td>
                                                <td>&#8358; {utility.formatPrice(item.vendorPrice)}</td>
                                                <td>{item.quantity} Pics</td>
                                                <td>
                                                    <button className={
                                                        item.isApproved ? "block-available" : 
                                                        item.isPending ? "block-pending" : 
                                                        "block-not-available"
                                                    }>
                                                        {item.isApproved ? "Approved" : 
                                                        item.isPending ? "Pending-approval" : 
                                                        "Not Approved"}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Top Selling --> */}

                    {/* Recent Order */}
                    <div className="col-12">
                        <div className="card top-selling overflow-auto">
                            <div className="card-body pb-0">
                            <h5 className="card-title">Recent Products Order</h5>
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Product Name</th>
                                            <th scope="col">Total Quantity </th>
                                            <th scope="col">Order Count</th>
                                            <th scope="col">Order status</th>
                                            <th scope="col">Date Order</th>
                                            <th scope="col">Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order.length > 0 ? (
                                            order.map((item, index) => (
                                                <tr key={index} style={{ fontSize: '1.5rem' }}>
                                                    <th scope="row"  >{index + 1} 
                                                        <span style={{marginRight : '10px'}} ><img src={item.images[0]} alt={item.productName} style={{ width: '100px', height: '100px', objectFit: 'cover' }} /></span>
                                                    </th>
                                                    <td>{item.productName}</td>
                                                    <td>{item.totalQuantity}</td>
                                                    <td>{item.orderCount}</td>
                                                    <td>{item.orderStatus}</td>
                                                    <td>{utility.convertCreatedTime(item.lastOrderDate)}</td>
                                                    <td>&#8358; {utility.formatPrice(item.totalAmount)}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center mt-5">
                                                    <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                        <h2>No Order Yet.</h2>
                                                    </span>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* Recent order end */}
                </section>
            </section>
        </>
    );
};

export default MerchantDashboard;
