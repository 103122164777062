import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaAngleRight } from "react-icons/fa6";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import SweetAlertComponent from "../utils/alert";
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from "../component/AuthContext";



const AddFlashSales = () => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [apiResponse, setApiResponse] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { role} = useAuth();
    const { setIsSaved } = useAuth();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProducts = async () => {
          const token = localStorage.getItem('token'); 
          try {
            const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/product/${page}/${limit}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
                'Content-Type': 'application/json',
              },
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProducts(data.products);
            setLoading(false);
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }
        };
    
        fetchProducts();
    }, [page, limit]);


    const [formData, setFormData] = useState({
        _id: '',
    });

    const handleSubmit = async (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        e.preventDefault();
    
        const { _id } = formData; // Get the selected product ID
        const token = localStorage.getItem('token'); 
        const url = `https://aino-tech-server.onrender.com/api/v1/product/${_id}/add-to-flash-sale`;
       
        try {
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
                },
                body: JSON.stringify({
                    "status":true
                }), 
            });
    
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
    
            const result = await response.json();
            setIsSaved(true);
            sweetAlertClass.showSweetAlert(1, "", "Product Has Been Added to Flash Sales");
            navigate('/dashboard/flashsales'); 
        } catch (error) {
            setError(error.message);
            console.error("There was an error!", error);
        }
    };
    

    return (
        <div>
            <div id="wrapper">
                <div id="page" className="">
                    <div className="layout-wrap">
                        <div className="section-content-right">
                            <div className="main-content">
                                <div className="main-content-inner">
                                    <div className="main-content-wrap">
                                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                            <h3>Create A New Product on Flash Sales</h3>
                                            <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                                                
                                                <li>
                                                    <div className="text-tiny">Create a New Flash Sales</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <form className="form-add-new-user form-style-2" onSubmit={handleSubmit}>
                                            <div className="wg-box">
                                                <div className="right flex-grow">
                                                    <fieldset className="name mb-24">
                                                        <div className="body-title mb-10">Select Product to Add To Flash Sales</div>
                                                        <select className="flex-grow" name="product" value={formData._id} onChange={(e) => setFormData({ ...formData, _id: e.target.value })} aria-required="true" required>
                                                            <option value="" disabled>Select Product</option>
                                                            {products.map(r => (
                                                                <option key={r._id} value={r._id}>{r.name}</option>
                                                            ))}
                                                        </select>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="bot">
                                                <button className="tf-button w180" type="submit">
                                                    Create Flash Sales
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="bottom-page">
                                    <div className="body-text">
                                        Copyright © 2024 Aino Tech Global. All rights reserved.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFlashSales;
