import React, { useEffect, useState } from "react";
import logo from "../../images/logo/logo.png";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../merchant/component/AuthContext";
import { MdSpaceDashboard } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { BsBank2 } from "react-icons/bs";
import { FaUserTie, FaFirstOrder } from "react-icons/fa";
import { BiSolidLogOutCircle } from "react-icons/bi";
import { Modal, Button } from "react-bootstrap";
import { PiSlidersHorizontalThin } from "react-icons/pi";
import { AiOutlineSliders } from "react-icons/ai";

const SiderBar = () => {
    const { merchantlogout } = useAuth();
    const [showProductModal, setShowProductModal] = useState(false); // Modal for product details

    return (
        <div>
            <aside id="sidebar" className="sidebar">
                <ul className="sidebar-nav" id="sidebar-nav">

                    <li className="nav-item">
                        <Link className="nav-link" to="/merchant/merchantdashboard">
                            <MdSpaceDashboard style={{ fontSize: '1.5em', marginRight: '8px' }} />
                            <span> Dashboard</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" to="">
                            <AiFillProduct style={{ fontSize: '1.5em', marginRight: '8px' }} />
                            <span>Merchant Product</span>
                            <i className="bi bi-chevron-down ms-auto"></i>
                        </Link>
                        <ul id="components-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
                            <li>
                                <Link to="/merchant/merchantproductlist">
                                    <span>All Products</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/merchant/addmerchantproduct">
                                    <span>Add New Product</span>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/merchant/merchantorder">
                            <FaFirstOrder style={{ fontSize: '1.5em', marginRight: '8px' }} />
                            <span>Merchant Order</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" to="">
                            <BsBank2 style={{ fontSize: '1.5em', marginRight: '8px' }} />
                            <span>Merchant Bank</span>
                            <i className="bi bi-chevron-down ms-auto"></i>
                        </Link>
                        <ul id="forms-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
                            <li>
                                <Link to="/merchant/merchantbank">
                                    <span>All Banks</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/merchant/addmerchantbank">
                                    <span>Add Bank</span>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/merchant/merchantdetails">
                            <FaUserTie style={{ fontSize: '1.5em', marginRight: '8px' }} />
                            <span>Merchant Profile</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="" onClick={() => setShowProductModal(true)}>
                            <BiSolidLogOutCircle style={{ fontSize: '1.5em', marginRight: '8px' }} />
                            <span>Log Out</span>
                        </Link>
                    </li>
                </ul>

                {/* Log Out Modal */}
                <Modal show={showProductModal} onHide={() => setShowProductModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Log Out Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h1>Are you sure you want to log out from the platform?</h1>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowProductModal(false)}>Close</Button>
                        <Button variant="success" onClick={merchantlogout}>Confirm</Button>
                    </Modal.Footer>
                </Modal>
            </aside>
        </div>
    );
}

export default SiderBar;
