import React, { useState } from "react";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import NoAdminImage from '../../images/logo/logo.png';
import SweetAlertComponent from "../../utils/alert";

const ForgetPassword = () => { 
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    
    const onSubmit = async (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        e.preventDefault();
        setError(null); // Reset any previous errors

        try {
            const response = await fetch("https://aino-tech-server.onrender.com/api/v1/vendor/forgot-password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                const data = await response.json();
                sweetAlertClass.showSweetAlert(2, "", data.message);
            } else if (response.ok) {
                const dataSent = await response.json();
                sweetAlertClass.showSweetAlert(1, "", dataSent.message);
                navigate("/changepassword");
            }
        } catch (err) {
            setError(["An error occurred. Please try again."]);
            console.log(error)
        }
    };
    

    return (
        <div className="container">
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex justify-content-center py-4">
                                <Link to="" className="logo d-flex align-items-center w-auto">
                                    <img src={NoAdminImage} alt="" className="large-image" />
                                </Link>
                            </div>

                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="pt-4 pb-2">
                                        <p className="text-center run">Provide your Company registered Email Address for Verification</p>
                                    </div>
                                    {Array.isArray(error) && error.length > 0 && (
                                        <div className="alert alert-danger">
                                            <ul>
                                                {error.map((err, index) => (
                                                    <li key={index}>{err.msg}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    <form onSubmit={onSubmit} className="row g-3">
                                        <p>Please Note that we are sending a one-time Password to your Email Address </p>
                                        <div className="col-12">
                                            <label htmlFor="yourEmail" className="form-label runs">Merchant Email</label>
                                            <input onChange={(e) => setEmail(e.target.value)} type="email" value={email} name="email" className="form-control" id="yourEmail" required/>
                                        </div>
                                        

                                        <div className="col-12">
                                            <button className="runned" type="submit">Send Email</button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                            <div className="credits">Developed by <Link to="">Aino-Tech Global</Link> </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ForgetPassword;
