import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import SweetAlertComponent from '../utils/alert';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [categories, setCategories] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [user, setUser] = useState([]);
  const [retailer, setRetailer] = useState([]);
  const [influencer, setInfluencer] = useState([]);
  const [profile, setProfile] = useState({});
  const [flashSales, setFlashSales] = useState([]);
  const [dashboard, setDashboard] = useState([]);
  const [role, setRole] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [products, setProducts] = useState([]);
  const [pickupstation, setPickupStations] = useState([]);
  const [voucher, setVoucher] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [ref, setRef] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  const fetchWithToken = async (url, options = {}) => {
    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...options.headers,
  };

  const response = await fetch(url, { ...options, headers });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Network response was not ok');
    }

    return response.json();
  };

  const handleSubmit = async (e) => {
    let sweetAlertClass = new SweetAlertComponent();
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/admin/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (!response.ok) {
        sweetAlertClass.showSweetAlert(2, "error", data.message);
        throw new Error('Network response was not ok');
      }

      if (response.ok) {
        sweetAlertClass.showSweetAlert(1, "Success", data.message);
        localStorage.setItem('adminData', JSON.stringify(data));
        localStorage.setItem('token', data.token);
        setIsAuthenticated(true);
      }
    } catch (error) {
      // Handle the error
      console.error('There was a problem with the login request:', error);
    }
  };

  // Other functions here...
  const getCategories = async (page) => {
    const categoriesPerPage = page;
    const limit = 30;
    const token = localStorage.getItem('token'); 
    try {
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/category/${categoriesPerPage}/${limit}`, 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCategories(data.categories);
      setTotalPages(Math.ceil(data.totalCount / limit));
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }; 

  const getSubCategories = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/category/sub-category/1/30', 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSubCategories(data.categories);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getAdmins = async (page) => {
    const adminPerPage = page;
    const limit = 30;
    try {
      const data = await fetchWithToken(`https://aino-tech-server.onrender.com/api/v1/admin/all-admins/${adminPerPage}/${limit}`);
        setAdmin(data.data);
        setTotalPages(Math.ceil(data.totalCount / limit));
        setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getProfile = async () => {
   const adminData = JSON.parse(localStorage.getItem('adminData'));
    try {
      const data = await fetchWithToken(`https://aino-tech-server.onrender.com/api/v1/admin/${adminData.id}`);
        setProfile(data);
        setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getRole = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/admin/get-all-roles',
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRole(data.roles);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getVendor = async (page) => {
    const vendorPerPage = 40;
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/vendors/get-all-vendors/${page}/${vendorPerPage}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setVendor(data.vendors);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getProduct = async (page) => {
    const productPerPage = 30;
    const token = localStorage.getItem('token'); 
    try {
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/product/${page}/${productPerPage}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setProducts(data.products);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // Banner 
  const getBannerList = async () => {
    const token = localStorage.getItem('token'); 
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/settings/get-sliders', 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSliders(data.sliders);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  // state 
  const getStates = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/utilities/get-states', 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setStates(data.data); 
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // Pick Up station
  const getPickUpStations = async (page) => {
    const token = localStorage.getItem('token');
    const stationsPerPage = 20;
    try {
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/settings/get-all-location/${page}/${stationsPerPage}`, 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPickupStations(data.locations); 
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // voucher 
  const getAllVoucher = async (page) => {
    const voucherPerPage = 20;
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/vouchers/list?page=${page}&limit=${voucherPerPage}`, 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setVoucher(data.vouchers); 
      setTotalPages(Math.ceil(data.vouchers.length / voucherPerPage));
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }
    // User 
    const getAllUser = async (page) => {
      const token = localStorage.getItem('token');
      const userPerPage = 20; 
      try {
        const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/users/get-all-users/${page}/${userPerPage}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        
        setUser(data.users);  // Set the users from the API response
        setTotalPages(Math.ceil(data.users.length / userPerPage)); // Set total pages
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    // Retailers
    const getAllRetailer = async (page) => {
      const token = localStorage.getItem('token');
      const userPerPage = 20; 
      try {
        const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/users/get-all-users/${page}/${userPerPage}?userType=retailer`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        
        setRetailer(data.users);  // Set the users from the API response
        setTotalPages(Math.ceil(data.users.length / userPerPage)); // Set total pages
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    // influences
    const getAllInfluencer = useCallback(async (page) => {
      const token = localStorage.getItem('token');
      const influencersPerPage = 20;
      try {
        const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/influencers/list?page=${page}&limit=${influencersPerPage}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        setInfluencer(data.influencers);
        setTotalPages(Math.ceil(data.influencers.length / influencersPerPage));
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }, []);
  
    
    // Get Flash Sales 
    const getAllFlashSales = async (page) => {
      const flashSalesPerPage = 20;
      const token = localStorage.getItem("token");
      setLoading(true);
      try {
        const response = await fetch(
          `https://aino-tech-server.onrender.com/api/v1/admin/inventory/get-all-flash-sales/${page}/${flashSalesPerPage}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to fetch flash sales");
        }
  
        const data = await response.json();
        setFlashSales(data.flashSaleProducts);
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching flash sales:", error.message);
        setLoading(false);
      }
    };

    //Referrals
    const getAllReferrals = async (page) => {
      const refPerPage = 20;
      const token = localStorage.getItem("token");
      const adminId = localStorage.getItem("adminData._id");
      setLoading(true);
      try {
        const response = await fetch(
          `https://aino-tech-server.onrender.com/api/v1/admin/get-admin-refs/${page}/${refPerPage}/${adminId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to fetch flash sales");
        }
  
        const data = await response.json();
        setRef(data.refs);
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Referrals:", error.message);
        setLoading(false);
      }
    };


    // Aino Dashbaord 
    const DashBoard = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/dashboard-stats` , 
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`, 
              'Content-Type': 'application/json',
            },
          });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setDashboard(data.stats); 
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }; 

  const logout = () => {
    localStorage.removeItem("adminData");
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    window.location.href = "/"; 
  };

  let timeoutId;

  const startLogoutTimer = () => {
    clearTimeout(timeoutId); 
      timeoutId = setTimeout(() => {
        logout(); 
        window.location.href = "/";
      }, 10 * 60 * 1000);
  };

  const resetTimer = () => {
    startLogoutTimer(); 
  };

  const setupActivityListeners = () => {
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);
  };

  const initializeInactivityLogout = () => {
    setupActivityListeners();
    startLogoutTimer(); 
  };

  useEffect(() => {
    if (isAuthenticated) {
      getCategories(currentPage);
      getSubCategories();
      getAdmins(currentPage);
      getProfile();
      getRole();
      getVendor();
      getStates();
      getPickUpStations(currentPage);
      getAllVoucher(currentPage);
      getAllReferrals();
      getBannerList();
      DashBoard();
      getProduct();
      initializeInactivityLogout();
    }
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider value={{
      handleSubmit,
      isAuthenticated,
      logout,
      categories,
      setIsSaved,
      dashboard,
      admin,
      user,
      retailer,
      products,
      profile,
      role,
      vendor,
      getVendor,
      getBannerList,
      flashSales,
      getRole,
      getProduct,
      getAllUser,
      getAllRetailer,
      getAllFlashSales,
      getAllInfluencer,
      getProfile,
      getAllReferrals,
      getCategories,
      subCategories,
      sliders,
      states,
      influencer,
      pickupstation,
      orderDetails,
      voucher,
      error,
      loading,
      totalPages,
      getAdmins
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
