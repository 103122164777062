import React, { useState } from "react";
import logo from "../images/logo/logo.png";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  MdGridView,
  MdCategory,
  MdInventory,
  MdOutlineShoppingCart,
  MdOutlineKeyboardDoubleArrowLeft,
  MdQrCode2,
} from "react-icons/md";
import { PiFilePlusLight } from "react-icons/pi";
import { LuUserPlus } from "react-icons/lu";
import { AiOutlineUser } from "react-icons/ai";
import { SlLayers } from "react-icons/sl";
import { CiLogout } from "react-icons/ci";
import { useAuth } from "./AuthContext";
import { Link, NavLink } from "react-router-dom";
import { FaPeopleArrows, FaShirtsinbulk } from "react-icons/fa";
import { GrUserAdmin } from "react-icons/gr";
import { RiAdvertisementLine } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";
import { IoLocationSharp } from "react-icons/io5";

const SideBar = () => {
  const { logout } = useAuth();
  const [openMenu, setOpenMenu] = useState({});
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const isMerchant =
    adminData && adminData.level?.name.toLowerCase() === "merchant";
  const isSuperAdmin =
    adminData && adminData.level?.name.toLowerCase() === "super admin";
  const isAdmin = adminData && adminData.level?.name.toLowerCase() === "admin";

  const handleToggle = (menu) => {
    setOpenMenu((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };

  const menuItems = [
    {
      path: "/dashboard",
      icon: <MdGridView />,
      label: "Dashboard",
      superAdminOnly: true,
    },

    {
      label: "Admin",
      icon: <GrUserAdmin />,
      superAdminOnly: true,
      subItems: [
        { path: "/dashboard/admin", label: "All Admin" },
        { path: "/dashboard/addadmin", label: "Add new Admin" },
      ],
    },

    {
      label: "User",
      icon: <LuUserPlus />,
      superAdminOnly: true,
      subItems: [{ path: "/dashboard/user", label: "All user" }],
    },

    {
      label: "Retailer",
      icon: <FaShirtsinbulk />,
      superAdminOnly: true,
      subItems: [{ path: "/dashboard/retailer", label: "All Retailer" }],
    },

    {
      label: "Referrals",
      icon: <FaPeopleArrows />,
      superAdminOnly: true,
      subItems: [{ path: "/dashboard/referrals", label: "All Referrals" }],
    },

    {
      label: "Merchant",
      icon: <LuUserPlus />,
      superAdminOnly: true,
      subItems: [{ path: "/dashboard/merchant", label: "All Merchant" }],
    },

    {
      label: "Aino Influencer",
      icon: <LuUserPlus />,
      superAdminOnly: true,
      subItems: [{ path: "/dashboard/influencer", label: "Aino Influencer" }],
    },

    {
      label: "Roles",
      icon: <AiOutlineUser />,
      superAdminOnly: true,
      subItems: [
        { path: "/dashboard/allroles", label: "All roles" },
        { path: "/dashboard/createroles", label: "Create role" },
      ],
    },

    {
      label: "Aino Banner",
      icon: <RiAdvertisementLine />,
      superAdminOnly: true,
      subItems: [
        { path: "/dashboard/advert", label: "Aino Banner" },
        { path: "/dashboard/addadvert", label: "Create New Banner" },
      ],
    },

    {
      label: "Categories",
      icon: <BiCategory />,
      superAdminOnly: true,
      subItems: [
        { path: "/dashboard/catergorieslist", label: "Categories" },
        { path: "/dashboard/newcatergories", label: "Create Categories" },
      ],
    },

    {
      label: "Sub Categories",
      icon: <MdCategory />,
      superAdminOnly: true,
      subItems: [
        { path: "/dashboard/subcategories", label: "Sub Categories" },
        {
          path: "/dashboard/NewSubCategories",
          label: "Create Sub Categories",
        },
      ],
    },

    {
      label: "Inventory",
      icon: <MdInventory />,
      superAdminOnly: true,
      subItems: [
        { path: "/dashboard/productlist", label: "Inventory" },
        { path: "/dashboard/addproduct", label: "Add Inventory" },
      ],
    },

    {
      label: "Order",
      icon: <PiFilePlusLight />,
      superAdminOnly: true,
      subItems: [{ path: "/dashboard/orderlist", label: "Order" }],
    },

    {
      label: "FlashSales",
      icon: <MdOutlineShoppingCart />,
      superAdminOnly: true,
      subItems: [
        { path: "/dashboard/flashsales", label: "Flash Sales" },
        { path: "/dashboard/addFlashSales", label: "Create Flash Sales" },
      ],
    },

    {
      label: "Transactions",
      icon: <PiFilePlusLight />,
      superAdminOnly: true,
      subItems: [
        { path: "/dashboard/transcationlist", label: "All Transactions" },
      ],
    },

    {
      label: "Pick up Location",
      icon: <IoLocationSharp />,
      superAdminOnly: true,
      subItems: [
        {
          path: "/dashboard/pickupstationlist",
          label: "Pick Up Location list",
        },
        { path: "/dashboard/newpickuplist", label: "Create New Location" },
      ],
    },

    {
      label: "Voucher Codes",
      icon: <MdQrCode2 />,
      superAdminOnly: true,
      subItems: [
        { path: "/dashboard/vouchercodelist", label: "Voucher Codes list" },
        { path: "/dashboard/newvouchercode", label: "Create New Voucher Code" },
      ],
    },

    { path: "#", label: "Logout", icon: <CiLogout />, onClick: logout },
  ];

  return (
    <>
      <div className="section-menu-left">
        <div className="box-logo">
          <Link to="/dashboard">
          <img src={logo} alt="Logo" id="logo_header_mobile" />
          </Link>
          <div className="button-show-hide">
            <MdOutlineKeyboardDoubleArrowLeft />
          </div>
        </div>

        <div className="center">
          <div className="nav-style">
            <ul className="menu-list">
              {menuItems.map((item, index) => (
                <React.Fragment key={index}>
                  {item.superAdminOnly &&
                  !isSuperAdmin ? null : item.subItems ? (
                    <li className="menu-item has-children">
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleToggle(item.label);
                        }}
                        className="menu-item-button"
                      >
                        <div className="icon">{item.icon}</div>
                        <div className="text">{item.label}</div>
                      </Link>
                      {openMenu[item.label] && (
                        <ul className="sub-menu">
                          {item.subItems.map((subItem, subIndex) => (
                            <li key={subIndex} className="sub-menu-item">
                              <NavLink to={subItem.path}>
                                <div className="text">{subItem.label}</div>
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ) : (
                    <li className="menu-item not-dropdown">
                      <Link to={item.path || "#"} onClick={item.onClick}>
                        <div className="icon">{item.icon}</div>
                        <div className="text">{item.label}</div>
                      </Link>
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
