import React, { useState, useEffect } from "react";
import NoAdminImage from '../../images/avatar/order.png';
import { Link } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";
import LoadingGif from '../../images/avatar/aino_loading.gif';
import Utlilty from "../../utils/func";
import { MdDeleteForever, MdArrowBackIosNew , MdOutlineViewInAr } from "react-icons/md";
import { FaRegIdCard } from "react-icons/fa6";
import { GrFormNextLink } from "react-icons/gr";
import { Modal, Button, Alert } from "react-bootstrap";

const MerchantOrder = () => {
    const [order, setOrder] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [error, setError] = useState(null);
    const utility = new Utlilty();

    const fetchOrder = async (pageNum = 1) => {
        const vendorToken = localStorage.getItem('vendorToken');
        try {
            const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/vendor/products-in-orders?page=${pageNum}&limit=30`, {
                method: 'GET',
                headers: {
                    'x-vendor-token': `${vendorToken}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setOrder(data.data.products);
            setTotalPages(data.data.totalPages);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrder(page);
    }, [page]);

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Product Order List</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/merchant/merchantdashboard">Dashbaord</Link></li>
                            <li className="breadcrumb-item active">Order List</li>
                        </ol>
                    </nav>
                </div>

                <section className="section">
                    {loading ? (
                        <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                            <p>Loading Merchant Order Link...</p>
                        </span>
                    ) : (
                        <>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {/* <div className="d-flex justify-content-between mt-5">
                                                <div>
                                                    <h4>Note that As a Merchant The Minimum, You are only Allowed to add three (3) bank Details which will be subjected to approval</h4>
                                                </div>
                                                <Link to="/merchant/addmerchantbank" type="button" className="btn btn-outline-primary"> <MdOutlineAdd /> Add Bank </Link>
                                            </div> */}

                                            <h5 className="card-title"></h5>
                                            <table className="table table-striped" style={{ fontSize: '1.5rem' }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Product Name</th>
                                                        <th scope="col">Total Quantity </th>
                                                        <th scope="col">Order Count</th>
                                                        <th scope="col">Order status</th>
                                                        <th scope="col">Date Order</th>
                                                        <th scope="col">Total Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {order.length > 0 ? (
                                                        order.map((item, index) => (
                                                            <tr key={index} style={{ fontSize: '1.5rem' }}>
                                                                <th scope="row"  >{index + 1} 
                                                                    <span style={{marginRight : '10px'}} ><img src={item.images[0]} alt={item.productName} style={{ width: '100px', height: '100px', objectFit: 'cover' }} /></span>
                                                                </th>
                                                                <td>{item.productName}</td>
                                                                <td>{item.totalQuantity}</td>
                                                                <td>{item.orderCount}</td>
                                                                <td>{item.orderStatus}</td>
                                                                <td>{utility.convertCreatedTime(item.lastOrderDate)}</td>
                                                                <td>&#8358; {utility.formatPrice(item.totalAmount)}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="7" className="text-center">
                                                                <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                                    <img src={NoAdminImage} alt="No Bank Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                                                                    <h2>No Merchant Order found on Aino Platform.</h2>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            <div className="d-flex justify-content-end mt-4">
                                                {page > 1 && (
                                                    <button className="btn btn-outline-info" onClick={handlePreviousPage}>
                                                        <MdArrowBackIosNew /> Previous
                                                    </button>
                                                )}
                                                <h4>Page {page} of {totalPages}</h4>
                                                {page < totalPages && (
                                                    <button className="btn btn-outline-secondary" onClick={handleNextPage} disabled={page === totalPages}>
                                                        <GrFormNextLink /> Next
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </section>
            </main>
        </>
    );
};

export default MerchantOrder;
