import React, { useState, useEffect } from "react";
import NoAdminImage from '../../images/avatar/avatar.png';
import { Link } from "react-router-dom";
import { useAuth } from "../../merchant/component/AuthContext";
import Utility from "../../utils/func"
import { FaUpload, FaTrashAlt } from "react-icons/fa";
import SweetAlertComponent from "../../utils/alert";
import { RxEyeOpen } from "react-icons/rx";
import { GoEyeClosed } from "react-icons/go";

const MerchantDetails = () => {
    const { profile  } = useAuth();
    const utility = new Utility();
    const [activeTab, setActiveTab] = useState("profile-overview");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false); 
    const [selectedImage, setSelectedImage] = useState(profile?.profileImg || NoAdminImage); // Add state for image preview
    const [editProfile, setEditProfile] = useState({
        firstName: "",
        lastName: "",
        storeDescription: "",
        storeName: ""

    });

    useEffect(() => {
        if (profile) {
            setEditProfile({
                firstName: profile.firstName || "",
                lastName: profile.lastName || "",
                storeDescription: profile.storeDescription || "",
                storeName: profile.storeName || ""
            });
        }
    }, [profile]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditProfile((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const imageUrl = await utility.uploadImage(file);
                setSelectedImage(imageUrl);
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        }
    };


    const switchTab = (tabId) => {
        setActiveTab(tabId); // Update the active tab state
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        let sweetAlertClass = new SweetAlertComponent();
        
        try {
            const vendorToken = localStorage.getItem('vendorToken');
            const response = await fetch("https://aino-tech-server.onrender.com/api/v1/vendor/edit-profile", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'x-vendor-token': `${vendorToken}`,
                },
                body: JSON.stringify({ ...editProfile, profileImg: selectedImage }),
            });

            if (response.ok) {
                sweetAlertClass.showSweetAlert(1, "", "Profile updated successfully!");
                switchTab("profile-overview");
            } else {
                const errorData = await response.json();
                sweetAlertClass.showSweetAlert(2, "Error", errorData.message || "Failed to update profile.");
            }
        } catch (error) {
            sweetAlertClass.showSweetAlert(2, "", "An error occurred. Please try again.");
        }
    };

    const handlePasswordChange = async (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        e.preventDefault();
        try {
            const vendorToken = localStorage.getItem('vendorToken');
            const response = await fetch("https://aino-tech-server.onrender.com/api/v1/vendor/change-password", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'x-vendor-token': `${vendorToken}`,
                },
                body: JSON.stringify({ currentPassword, newPassword }),
            });

            if (response.ok) {
                setChangePasswordSuccess(true);
                sweetAlertClass.showSweetAlert(1, "", "Password changed successfully!");
                switchTab("profile-overview"); 
            } else {
                const errorData = await response.json();
                sweetAlertClass.showSweetAlert(2, "errorData", "Failed to change password. Please try again.");
                // alert(errorData.message || "Failed to change password. Please try again.");
            }
        } catch (error) {
            alert("An error occurred. Please try again.");
            sweetAlertClass.showSweetAlert(2, "", "An error occurred. Please try again.");
        }
    };

    return (
        <main id="main" className="main">
            <div className="pagetitle">
            <h1>Profile</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="">DashBoard</Link></li>
                        <li className="breadcrumb-item active">Merchant Profile</li>
                    </ol>
                </nav>
            </div>
            <section className="section profile">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <img src={profile?.profileImg || NoAdminImage} alt={profile.firstName} className="rounded-circle"/>
                                <h2>{profile?.firstName} {profile?.lastName}</h2>
                                <h3>{profile?.storeName || ""}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className="card">
                            <div className="card-body pt-3">
                                <ul className="nav nav-tabs nav-tabs-bordered" style={{ fontSize: '1.5rem' }}>
                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === "profile-overview" ? "active" : ""}`} onClick={() => switchTab("profile-overview")}>Overview</button>
                                    </li>

                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === "profile-edit" ? "active" : ""}`} onClick={() => switchTab("profile-edit")}>Edit Profile</button>
                                    </li>

                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === "profile-change-password" ? "active" : ""}`} onClick={() => switchTab("profile-change-password")}>Change Password  </button>
                                    </li>
                                </ul>
                                <div className="tab-content pt-2" style={{ fontSize: '1.5rem' }}>
                                    <div className={`tab-pane fade ${activeTab === "profile-overview" ? "show active" : ""}`} id="profile-overview">
                                        <h5 className="card-title">Profile Details</h5>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Full Name</div>
                                            <div className="col-lg-9 col-md-8">{profile?.firstName} {profile?.lastName}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Store Name</div>
                                            <div className="col-lg-9 col-md-8">{profile?.storeName || ""}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Store Description</div>
                                            <div className="col-lg-9 col-md-8">{profile?.storeDescription || ""}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Phone</div>
                                            <div className="col-lg-9 col-md-8">{profile?.phoneno || "No Phone Number"}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Email</div>
                                            <div className="col-lg-9 col-md-8">{profile?.email || ""}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Account Status</div>
                                            <div className="col-lg-9 col-md-8">{profile?.isVerified}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Wallet Balance</div>
                                            <div className="col-lg-9 col-md-8"> &#8358; {utility.profile?.wallet || 0}</div>
                                        </div>

                                        <h5 className="card-title">Bank Details</h5>
                                        {Array.isArray(profile?.bankDetails) && profile.bankDetails.length > 0 ? (
                                            profile.bankDetails.map((bankDetail, index) => (
                                                <div key={index}>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 label">Bank Name</div>
                                                        <div className="col-lg-9 col-md-8">{bankDetail.bankName || ""}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 label">Account Number</div>
                                                        <div className="col-lg-9 col-md-8">{bankDetail.accountNumber || ""}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 label">Account Name</div>
                                                        <div className="col-lg-9 col-md-8">{bankDetail.accountName || ""}</div>
                                                    </div>
                                                </div>
                                                
                                            ))
                                        ) : (
                                            <div className="row">
                                                <div className="col-lg-9 col-md-8">No bank details available</div>
                                            </div>
                                        )}
                                    </div>

                                    {/* <!-- Profile Edit Form --> */}
                                    <div className={`tab-pane fade ${activeTab === "profile-edit" ? "show active" : ""} pt-3`} id="profile-edit" >
                                        <form onSubmit={handleProfileUpdate}>
                                            <div className="row mb-3">
                                                <label htmlFor="profileImage" className="col-md-4 col-lg-3 col-form-label">Profile Image</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <img src={selectedImage} alt="Profile" className="rounded-circle mb-2" />
                                                    <div className="pt-2">
                                                        <label className="btn btn-primary btn-sm" style={{marginRight: "10px"}}>
                                                            <FaUpload /> <input type="file" onChange={handleFileChange} style={{ display: "none" }} />
                                                        </label>
                                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => setSelectedImage(NoAdminImage)}><FaTrashAlt /></button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label for="firstName" className="col-md-4 col-lg-3 col-form-label">First Name</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="firstName" type="text" className="form-control" id="firstName" value={editProfile.firstName} onChange={handleInputChange}  />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label for="lastName" className="col-md-4 col-lg-3 col-form-label">Last Name</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="lastName" type="text" className="form-control" id="lastName" value={editProfile.lastName} onChange={handleInputChange} />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label for="storeDescription" className="col-md-4 col-lg-3 col-form-label">Store Description</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <textarea name="storeDescription" className="form-control" id="storeDescription" style={{height: "100px"}} value={editProfile.storeDescription} onChange={handleInputChange}></textarea>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label for="storename" className="col-md-4 col-lg-3 col-form-label">Store Name</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="storename" type="text" className="form-control" id="storename" value={editProfile.storeName} onChange={handleInputChange} />
                                                </div>
                                            </div>

                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary">Save Changes</button>
                                            </div>
                                        </form>
                                    
                                    </div>

                                    {/* Chnage Password */}
                                    <div className={`tab-pane fade ${activeTab === "profile-change-password" ? "show active" : ""} pt-3 `} id="profile-change-password">
                                        <form onSubmit={handlePasswordChange}>
                                            <div className="row mb-3">
                                                <label for="currentPassword" className="col-md-4 col-lg-3 col-form-label">Current Password</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="currentPassword" type={showPassword ? "text" : "password"} className="form-control" id="currentPassword" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)}/>
                                                    <span className="password-toggle-icon" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '20px', cursor: 'pointer', fontSize: '1.5rem' }}>
                                                        {showPassword ? <GoEyeClosed /> : <RxEyeOpen />}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label for="newPassword" className="col-md-4 col-lg-3 col-form-label">New Password</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="newPassword" type={showPassword ? "text" : "password"} className="form-control" id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                                                    <span className="password-toggle-icon" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '20px', cursor: 'pointer', fontSize: '1.5rem' }}>
                                                        {showPassword ? <GoEyeClosed /> : <RxEyeOpen />}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary">Change Password</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default MerchantDetails;
