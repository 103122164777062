import React, { useState, useEffect } from "react";
import NoAdminImage from '../../images/avatar/bank.jpg';
import { Link } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";
import LoadingGif from '../../images/avatar/aino_loading.gif';
import Utlilty from "../../utils/func";
import { FaRegIdCard } from "react-icons/fa6";
import { Modal, Button, Alert } from "react-bootstrap";
import SweetAlertComponent from "../../utils/alert";

const MerchantBank = () => {
    const [bank, setBank] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showProductModal, setShowProductModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [error, setError] = useState(null);
    const utility = new Utlilty();
    const [formData, setFormData] = useState({
        accountId: "",
        imagePath: "",
    });

    const fetchBank = async () => {
        const vendorToken = localStorage.getItem('vendorToken');
        try {
            const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/vendor/my-bank-details`, {
                method: 'GET',
                headers: {
                    'x-vendor-token': `${vendorToken}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBank(data.bankDetails);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBank();
    }, []);

    const handleShowProductModal = (product) => {
        setSelectedProduct(product);
        setFormData((prevData) => ({
            ...prevData,
            accountId: product,
        }));
        setShowProductModal(true);
    };

    const handleCloseProductModal = () => {
        setShowProductModal(false);
        setSelectedProduct(null);
        setFormData({ accountId: "", imagePath: "" });
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0]; // Only take the first selected file
        if (file) {
            try {
                const imageUrl = await utility.uploadImage(file);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    imagePath: imageUrl, // Set the image URL to imagePath
                }));
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    const handleUpload = async () => {
        let sweetAlertClass = new SweetAlertComponent();
        const vendorToken = localStorage.getItem('vendorToken');
        try {
            const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/vendor/update-vendor-id-card`, {
                method: 'PUT',
                headers: {
                    'x-vendor-token': `${vendorToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (!response.ok) {
                throw new Error('Failed to upload the means of identification.');
            }

            const data = await response.json();
            // alert("Means of identification uploaded successfully!");
            sweetAlertClass.showSweetAlert(1, "", data.message);
            handleCloseProductModal();
            fetchBank(); // refresh bank details after upload
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Withdraw Bank List</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/merchant/merchantdashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active">Bank List</li>
                        </ol>
                    </nav>
                </div>

                <section className="section">
                    {loading ? (
                        <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                            <p>Loading Merchant Details Bank...</p>
                        </span>
                    ) : (
                        <>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between mt-5">
                                                <div>
                                                    <h4>Note that as a Merchant, you are only allowed to add up to three bank details which will be subject to approval.</h4>
                                                </div>
                                                <Link to="/merchant/addmerchantbank" className="btn btn-outline-primary" style={{ fontSize: '1.5rem' }}>
                                                    <MdOutlineAdd style={{ width: '1.5rem' }}/> Add Bank
                                                </Link>
                                            </div>
                                            <table className="table table-striped" style={{ fontSize: '1.5rem' }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Bank Name</th>
                                                        <th scope="col">Account Name</th>
                                                        <th scope="col">Account Number</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bank.length > 0 ? (
                                                        bank.map((item, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{index + 1}
                                                                    <span>
                                                                        <img src={item.idCard} alt="Means of ID" style={{ width: '50px', height: '30px', cursor: 'pointer' }} />
                                                                    </span>
                                                                </th>
                                                                <td>{item.bankName}</td>
                                                                <td>{item.accountName}</td>
                                                                <td>{item.accountNumber}</td>
                                                                <td>
                                                                    <button onClick={() => handleShowProductModal(item._id)} className="btn btn-outline-success">
                                                                        <FaRegIdCard />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="5" className="text-center">
                                                                <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                    <img src={NoAdminImage} alt="No Merchant Bank found on Aino Platform" style={{ width: '300px', height: '300px', borderRadius: '50%' }} />
                                                                    <h2>No Merchant Bank found on Aino Platform.</h2>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </section>

                <Modal show={showProductModal} onHide={handleCloseProductModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Upload Means of Identification for Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <fieldset className="image mt-24">
                                <div className="body-title mb-10">Upload Means of Identification</div>
                                <input type="file" accept="image/*" onChange={handleFileChange} aria-required="true" />
                            </fieldset>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseProductModal}>
                            Close
                        </Button>
                        <Button variant="success" onClick={handleUpload}>
                            Upload Means of Identification
                        </Button>
                    </Modal.Footer>
                </Modal>
            </main>
        </>
    );
};

export default MerchantBank;
