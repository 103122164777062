import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { FaAngleRight } from "react-icons/fa6";
import { SlCloudUpload } from "react-icons/sl";
import { useAuth } from "../component/AuthContext";
import SweetAlertComponent from "../utils/alert";
import { useNavigate, Link } from 'react-router-dom';

const AddProduct = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [keyfeatures, setKeyfeatures] = useState("");
  const { categories } = useAuth();
  const { vendor } = useAuth();
  const { setIsSaved } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    longDesc: "",
    quantity: "",
    price: "",
    vendorPrice: "",
    keyfeatures: "",
    category: "",
    wholesaler: "",
    minOrderQty: "",
    priceRanges: [{ minQuantity: "", maxQuantity: "", price: "" }],
    vendorPriceRanges: [{ minQuantity: "", maxQuantity: "", price: "" }],
    images: [],
    color: "",
  });

  const [priceRanges, setPriceRanges] = useState([
    { minQuantity: '', maxQuantity: '', price: '' }
  ]);

  const [vendorPriceRanges, setVendorPriceRanges] = useState([
    { minQuantity: '', maxQuantity: '', price: '' }
  ]);

  const handlePriceRangeChange = (index, e) => {
    const { name, value } = e.target;
    const newPriceRanges = [...formData.priceRanges];
    newPriceRanges[index][name] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      priceRanges: newPriceRanges,
    }));
  };

  const handleVendorPriceRangeChange = (index, e) => {
    const { name, value } = e.target;
    const newVendorPriceRanges = [...formData.priceRanges];
    newVendorPriceRanges[index][name] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      vendorPriceRanges: newVendorPriceRanges,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddPriceRange = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      priceRanges: [...prevFormData.priceRanges, { minQuantity: "", maxQuantity: "", price: "" }],
    }));
  };

  const handleAddVendorPriceRange = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      vendorPriceRanges: [...prevFormData.vendorPriceRanges, { minQuantity: "", maxQuantity: "", price: "" }],
    }));
  };

  const handleRemovePriceRange = (index) => {
    const newPriceRanges = formData.priceRanges.filter((_, i) => i !== index);
    setFormData((prevFormData) => ({
      ...prevFormData,
      priceRanges: newPriceRanges,
    }));
  };

  const handleRemoveVendorPriceRange = (index) => {
    const newVendorPriceRanges = formData.vendorPriceRanges.filter((_, i) => i !== index);
    setFormData((prevFormData) => ({
      ...prevFormData,
      vendorPriceRanges: newVendorPriceRanges,
    }));
  };

  // Handle file changes for multiple image upload and preview
  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files); 
    const uploadedImages = [];

    for (const file of files) {
      try {
        const imageUrl = await uploadImage(file);
        uploadedImages.push(imageUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      images: [...prevFormData.images, ...uploadedImages],
    }));
  };


  // Remove an image by its index
  const handleRemoveImage = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: prevFormData.images.filter((_, i) => i !== index),
    }));
  };

  // Upload image and get the URL
  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await fetch('https://ainotechglobal.com/upload.php', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        return data.url;  // Return the image URL
      } else {
        throw new Error('Failed to upload image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

    // Handle form submission
    const handleSubmit = async (e) => {
      let sweetAlertClass = new SweetAlertComponent();
      e.preventDefault();

      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://aino-tech-server.onrender.com/api/v1/product', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
            body: JSON.stringify({
              name: formData.name,
              description: formData.description,
              longDesc: formData.longDesc,
              quantity: formData.quantity,
              price: formData.price,
              keyfeatures: formData.keyfeatures,
              category: formData.category,
              wholesaler: formData.wholesaler,
              images: formData.images,
              minOrderQty: formData.minOrderQty,
              priceRanges: formData.priceRanges,
              vendorPriceRanges: formData.vendorPriceRanges,
              vendorPrice: formData.vendorPrice,
              color: formData.color
            }),
        });
  
        if (response.ok) {
          const result = await response.json();
          setIsSaved(true);
          sweetAlertClass.showSweetAlert(1, "", "New Product Added");
          setFormData({name: "", description: "", longDesc: "", quantity: "", price: "", keyfeatures: "", category: "", wholesaler: "", images: [], minOrderQty: "", priceRanges: [], vendorPriceRanges: [], vendorPrice: "", color: ""});
          navigate('/dashboard/productlist'); 
          
        } else {
          const errorData = await response.json();
          sweetAlertClass.showSweetAlert(2, "", "Error adding product");
        }
      }  
        catch (error) {
        setError(error.message);
        sweetAlertClass.showSweetAlert(2, "", "Submission failed, please try again.");
      }
    };

  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3>Create a Product, Please Note that before any Product show for Either the User or Retailers, It is Subjected to Final Approval </h3>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        
                      </ul>
                    </div>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <form className="tf-section-2 form-add-product" onSubmit={handleSubmit}>
                      <div className="wg-box">
                        <fieldset className="name">
                          <div className="body-title mb-10">Product name <span className="tf-color-1">*</span></div>
                          <input className="mb-10" type="text" placeholder="Enter product name" name="name" value={formData.name} onChange={handleChange} aria-required="true" required />
                        </fieldset>
                        <fieldset className="category">
                          <div className="body-title mb-10">Category <span className="tf-color-1">*</span></div>
                          <div className="select">
                            <select name="category" value={formData.category} onChange={handleChange} required>
                              <option>Choose category</option>
                              {categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </fieldset>
                        <fieldset className="category">
                          <div className="body-title mb-10">Vendor <span className="tf-color-1">*</span></div>
                          <div className="select">
                            <select name="wholesaler" value={formData.wholesaler} onChange={handleChange} required >
                              <option>Choose Vendors</option>
                              {vendor.map((vendors) => (
                                <option key={vendors._id} value={vendors._id}>
                                  {vendors.storeName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </fieldset>
                        <fieldset className="description">
                          <div className="body-title mb-10">Description <span className="tf-color-1">*</span></div>
                          <textarea className="mb-10" placeholder="Description" name="description" value={formData.description} onChange={handleChange} required></textarea>
                        </fieldset>
                        <fieldset className="description">
                          <div className="body-title mb-10">Long Description <span className="tf-color-1">*</span></div>
                          <textarea className="mb-10" placeholder="Long Description" name="longDesc" value={formData.longDesc} onChange={handleChange} required></textarea>
                        </fieldset>
                        <div className="cols gap22">
                          <fieldset className="name">
                            <div className="body-title mb-10">Quantity Available <span className="tf-color-1">*</span></div>
                            <input className="mb-10" type="text" placeholder="Enter Available Quantity" name="quantity" value={formData.quantity} onChange={handleChange} required/>
                          </fieldset>
                          <fieldset className="name">
                            <div className="body-title mb-10">Cost Price <span className="tf-color-1">*</span></div>
                            <input className="mb-10" type="number" placeholder="Enter Price Cost" name="price" value={formData.price} onChange={handleChange} required/>
                          </fieldset>
                          <fieldset className="name">
                            <div className="body-title mb-10">Vendor Price <span className="tf-color-1">*</span></div>
                            <input className="mb-10" type="number" placeholder="Enter Price Vendor" name="vendorPrice" value={formData.vendorPrice} onChange={handleChange} required/>
                          </fieldset>
                        </div>
                      </div>
                      <div className="wg-box">
                        <fieldset>
                          <div className="body-title mb-10">Upload images</div>
                          <div className="upload-image mb-16">
                            {formData.images.length === 0 ? (
                              <div className="item">
                                <label className="uploadfile" htmlFor="myFile">
                                  <span className="icon">
                                    <SlCloudUpload color="blue" size={40} />
                                  </span>
                                  <span className="text-tiny">
                                    Drop your images here or select{" "}
                                    <span className="tf-color">click to browse</span>
                                  </span>
                                  <input type="file" id="myFile" name="filename" accept="image/*" onChange={handleFileChange} multiple required
                                  />
                                </label>
                              </div>
                            ) : (
                              formData.images.map((image, index) => (
                                <div key={index} className="item preview-container">
                                  <img src={image} alt={`preview-${index}`} className="preview-image" />
                                  <button className="close-button" onClick={() => handleRemoveImage(index)} > &times;
                                  </button>
                                </div>
                              ))
                            )}
                          </div>
                          <div className="body-text">
                            You need to add at least 2 images. Pay attention to the quality of the pictures you add, comply with the background color standards. Pictures must be in certain dimensions. Notice that the product shows all the details.
                          </div>
                        </fieldset>
                        
                        <div className="cols gap22">
                          <fieldset className="name">
                            <div className="body-title mb-10">Key Features <span className="tf-color-1">*</span></div>
                            <input className="mb-10" type="text" placeholder="Enter Product Key Features" name="keyfeatures" value={formData.keyfeatures} onChange={handleChange} required/>
                          </fieldset>
                          <fieldset className="name">
                            <div className="body-title mb-10">Min Order qty <span className="tf-color-1">*</span></div>
                            <input className="mb-10" type="number" placeholder="Enter Min Order qty" name="minOrderQty" value={formData.minOrderQty} onChange={handleChange} required/>
                          </fieldset>
                          <fieldset className="name">
                            <div className="body-title mb-10">Available Color <span className="tf-color-1">*</span></div>
                            <input className="mb-10" type="text" placeholder="Enter Color" name="color" value={formData.color} onChange={handleChange} required/>
                          </fieldset>
                        </div>
                        <div className="cols gap22">
                          {formData.priceRanges.map((range, index) => (
                            <div key={index}>
                              <fieldset className="name">
                                <div className="body-title mb-10"> Ranges for Min Quantity <span className="tf-color-1">*</span></div>
                                <input className="mb-10" type="number" placeholder="Enter Min Quantity" name="minQuantity" value={range.minQuantity} onChange={(e) => handlePriceRangeChange(index, e)} required />
                              </fieldset>
                              <fieldset className="name">
                                <div className="body-title mb-10">Ranges for Max Quantity <span className="tf-color-1">*</span></div>
                                <input className="mb-10" type="number" placeholder="Enter Max Quantity" name="maxQuantity" value={range.maxQuantity} onChange={(e) => handlePriceRangeChange(index, e)} required />
                              </fieldset>
                              <fieldset className="name">
                                <div className="body-title mb-10">Retailers Prices <span className="tf-color-1">*</span></div>
                                <input className="mb-10" type="number" placeholder="Enter Prices" name="price" value={range.price} onChange={(e) => handlePriceRangeChange(index, e)} required />
                              </fieldset>
                              {/* The button to remove a price range is now placed below the fieldsets */}
                              {index > 0 && (
                                <div className="cols gap10 mb-10">
                                  <button className="tf-button w-full" type="button" onClick={() => handleRemovePriceRange(index)}>Remove Price Range</button>
                                </div>
                              )}
                            </div>
                          ))}
                          {/* Add Price Range button placed at the bottom */}
                          <div className="cols gap10"> 
                            <button className="tf-button w-full" type="button" onClick={handleAddPriceRange}>
                              Add Price Range
                            </button>
                          </div>
                        </div>
                        <div className="cols gap22">
                          {formData.vendorPriceRanges.map((range, index) => (
                            <div key={index}>
                              <fieldset className="name">
                                <div className="body-title mb-10">Vendor Ranges for Min Quantity <span className="tf-color-1">*</span></div>
                                <input className="mb-10" type="number" placeholder="Enter Min Quantity" name="venderMinQuantity" value={range.minQuantity} onChange={(e) => handleVendorPriceRangeChange(index, e)} required />
                              </fieldset>
                              <fieldset className="name">
                                <div className="body-title mb-10">Vendor Ranges for Max Quantity <span className="tf-color-1">*</span></div>
                                <input className="mb-10" type="number" placeholder="Enter Max Quantity" name="vendorMaxQuantity" value={range.maxQuantity} onChange={(e) => handleVendorPriceRangeChange(index, e)} required />
                              </fieldset>
                              <fieldset className="name">
                                <div className="body-title mb-10">vendor Retailers Prices <span className="tf-color-1">*</span></div>
                                <input className="mb-10" type="number" placeholder="Enter Prices" name="vendorPrice" value={range.price} onChange={(e) => handleVendorPriceRangeChange(index, e)} required />
                              </fieldset>
                              {/* The button to remove a price range is now placed below the fieldsets */}
                              {index > 0 && (
                                <div className="cols gap10 mb-10">
                                  <button className="tf-button w-full" type="button" onClick={() => handleRemoveVendorPriceRange(index)}>Remove Vendor Price Range</button>
                                </div>
                              )}
                            </div>
                          ))}
                          {/* Add Price Range button placed at the bottom */}
                          <div className="cols gap10"> 
                            <button className="tf-button w-full" type="button" onClick={handleAddVendorPriceRange}>
                              Add Vendor Price Range
                            </button>
                          </div>
                        </div>
                        <div className="cols gap10">
                          <button className="tf-button w-full" type="submit">
                            Add product
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
