import React, { useState } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../component/AuthContext";
import { useNavigate } from "react-router-dom";
import NoAdminImage from '../images/logo/logo.png';
import { Link, NavLink} from "react-router-dom"

const InfluencerLogin = () => {
  const { handleSubmit } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("")

  const onSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(e);
    const vendorLevel = localStorage.getItem('adminData.level.name');
    if (vendorLevel === 'vendor') {
      navigate('/dashboard/vendorindex'); 
    } else {
      navigate('/dashboard'); 
    }
  };
  

  return (
    <div id="wrapper">
      <div id="page" className="">
        <div className="wrap-login-page">
          <div className="flex-grow flex flex-column justify-center gap30">
            <Link to="/" id="site-logo-inner"> 
              <img
                  src={NoAdminImage}
                  alt="Aino Tech Logo" />
            </Link>
            <div className="login-box">
              <div>
                <h3 className="fw-bold">WELCOME, AINO INFLUENCER</h3>
                <div className="body-text">
                  Enter your email & password to login
                </div>
              </div>
              <form onSubmit={onSubmit} className="form-login flex flex-column gap24">
                <fieldset className="email">
                  <div className="body-title mb-10">
                   Brand Email address <span className="tf-color-1">*</span>
                  </div>
                  <input onChange={(e) => setEmail(e.target.value)} className="flex-grow w-100 rounded-1 p-2 border border-color-#ECF0F4 " type="email" placeholder="Enter your email address" name="email"
                    tabIndex="0" value={email} aria-required="true" required=""
                  />
                </fieldset>
                <fieldset className="password">
                  <div className="body-title mb-10 mt-3">
                    Brand Password <span className="tf-color-1">*</span>
                  </div>
                  <input onChange={(e) => setPassword(e.target.value)} className="password-input w-100 rounded-1 p-2 border border-color-#ECF0F4" type="password" placeholder="Enter your password" name="password" tabIndex="0" value={password} aria-required="true" required="" />
                  <span className="show-pass">
                    <i className="icon-eye view"></i>
                    <i className="icon-eye-off hide"></i>
                  </span>
                </fieldset>
                {/* <div className="flex justify-between items-center mt-3 w-100 okay">
                  
                  <a href="#" className="body-text tf-color">
                    Forgot password?
                  </a>
                </div> */}
                <button type="submit" className="tf-button w-full">
                  Login
                </button>
              </form>


            </div>
          </div>
          <div className="text-tiny">Copyright © 2024 Aino Tech Global.</div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerLogin;
