import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from '../component/Sidebar';
import Header from '../component/Header';
import PrivateRoute from '../component/PrivateRoute';
import '../App.css'

const DashboardLayout = () => {
    return (

        <div className="container-fluid">
            <div className="row">
                <aside className="layout-wrap">
                    <SideBar />
                </aside>
                <div className="section-content-right">
                    <header className="">
                        <Header />
                    </header>
                    <main className="p-3">
                        <PrivateRoute>
                            <Outlet />
                        </PrivateRoute>
                    </main>
                </div>
            </div>
        </div>

    )
}

export default DashboardLayout;