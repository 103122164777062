import React, { useState, useEffect } from "react";
import { useAuth } from "../component/AuthContext";
import { FaAngleRight, FaPlus, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import NoAdminImage from '../images/avatar/voucher.jpg';
import LoadingGif from '../images/avatar/aino_loading.gif';
import Utlilty from "../utils/func";

const FlashSalesList = () => {
  const { getAllFlashSales, flashSales, totalPages, loading } = useAuth(); 
  const [page, setPage] = useState(1);
  const utility = new Utlilty();

  useEffect(() => {
    if (flashSales.length === 0) {
      getAllFlashSales(page);
    }
  }, [page, getAllFlashSales, flashSales]);

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '150px'  }}>
        <img src={LoadingGif} alt="Loading" style={{  width: '200px', height: '200px', objectFit: 'cover' }} />
        <p>All Product On Flash Sales Loading...</p>
      </div>
    );
  }

  return (
    <div id="wrapper">
      <div id="page" className="">
        <div className="layout-wrap">
          <div className="section-content-right">
            <div className="main-content">
              <div className="main-content-inner">
                <div className="main-content-wrap">
                  <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                    <h3>Available Product On Flash Sales</h3>
                    <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                      <li>
                        <Link to="/" style={{ textDecoration: "none" }}>
                          <div className="text-tiny">Dashboard</div>
                        </Link>
                      </li>
                      <li>
                        <FaAngleRight />
                      </li>
                      <li>
                        <Link to="#" style={{ textDecoration: "none" }}>
                          <div className="text-tiny">Flash Sales</div>
                        </Link>
                      </li>
                      <li>
                        <FaAngleRight />
                      </li>
                      <li>
                        <div className="text-tiny">Available Flash Sales List</div>
                      </li>
                    </ul>
                  </div>
                  <div className="wg-box">
                    <div className="flex items-center justify-between gap10 flex-wrap">
                      <div className="wg-filter flex-grow"></div>
                      <Link className="tf-button style-1 w208" to="/dashboard/addFlashSales">
                        <FaPlus size={20} />
                        New FlashSales
                      </Link>
                    </div>
                    <div className="wg-table-wrap">
                      <div className="wg-table table-product-list">
                        <ul className="table-title flex gap10 mb-14">
                          <li>
                            <div className="body-title">Product Name</div>
                          </li>
                          <li>
                            <div className="body-title">Price</div>
                          </li>
                        </ul>
                        <ul className="flex flex-column">
                          {flashSales.length > 0 ? (
                            flashSales.map((sales, index) => (
                              <li key={sales._id} className="product-item gap14">
                                <div className="body-text">{index + 1}</div>
                                <div className="image no-bg">
                                  <img src={sales.images[0]} alt="" className="" />
                                </div>
                                <div className="flex items-center justify-between gap20 flex-grow">
                                  <div className="name">
                                    <Link to="productlist" className="body-title-2">
                                      {sales.name}
                                    </Link>
                                  </div>
                                  <div className="body-text">&#8358; {utility.formatPrice(sales.price)}</div>
                                </div>
                              </li>
                            ))
                          ) : (
                            <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                              <img src={NoAdminImage} alt="No Flash Sales Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                              <h2>No Flash Sales found.</h2>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="body-text">
                      Please Note that Only Approved Products can be added as Flash Sales Product.
                    </div>
                    <div className="divider"></div>
                    <div className="flex items-center justify-between flex-wrap gap10">
                      <div className="wg-pagination-wrap"></div>
                      <ul className="wg-pagination">
                        <li>
                          <Link to="#" onClick={handlePreviousPage} disabled={page === 1} style={{ cursor: page === 1 ? "not-allowed" : "pointer" }}>
                            <FaChevronLeft />
                          </Link>
                        </li>
                        <li>
                          <span>{page}</span>
                        </li>
                        <li>
                          <Link to="#" onClick={handleNextPage} disabled={page === totalPages} style={{ cursor: page === totalPages ? "not-allowed" : "pointer" }}>
                            <FaChevronRight />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-page">
                <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlashSalesList;
