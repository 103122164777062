import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "../component/Sidebar";
import Header from "../component/Header";
import { FaAngleRight } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa";
import { FiTruck } from "react-icons/fi";
import { FaChevronLeft } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { FaRegTrashCan } from "react-icons/fa6";
import { FaRegFileLines } from "react-icons/fa6";
import user5 from "../images/avatar/admin.png";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";


const OrderTracking = () => {
  
  return (
    <div>
      <div id="wrapper">
        <div id="page"    className  ="">
          <div className  ="layout-wrap">
            <div className  ="section-content-right">
              <div className ="main-content">
                    <div className ="main-content-inner">
                        <div className ="main-content-wrap">
                            <div className ="flex items-center flex-wrap justify-between gap20 mb-27">
                                <h3>Track Order</h3>
                                <ul className ="breadcrumbs flex items-center flex-wrap justify-start gap10">
                                    <li>
                                        <Link to="/" style={{ textDecoration: "none" }}><div className="text-tiny">Dashboard</div></Link>
                                    </li>
                                    <li>
                                    <FaAngleRight />
                                    </li>
                                    <li>
                                        <Link to="#" style={{ textDecoration: "none" }}><div className="text-tiny">Order</div></Link>
                                    </li>
                                    <li>
                                    <FaAngleRight />
                                    </li>
                                    <li>
                                        <div  className ="text-tiny" style={{ textDecoration: "none" }}>Track Order</div>
                                    </li>
                                </ul>
                            </div>
                            <div  className ="wg-box mb-20">
                                <div  className ="order-track">
                                    <div  className ="image">
                                    <img src={user5} alt=""   className ="" />
                                    </div>
                                    <div  className ="content">
                                        <h5  className ="mb-20">Pouch Pocket Hoodie Orange</h5>
                                        <div  className ="infor mb-10">
                                            <div  className ="body-text">Order ID</div>
                                            <div  className ="body-title-2">#192847</div>
                                        </div>
                                        <div  className ="infor mb-10">
                                            <div  className ="body-text">Brand:</div>
                                            <div  className ="body-title-2">20 Nov 2023</div>
                                        </div>
                                        <div  className ="infor mb-10">
                                            <div  className ="body-text">Order Placed:</div>
                                            <div  className ="body-title-2">20 Nov 2023</div>
                                        </div>
                                        <div  className ="infor mb-20">
                                            <div  className ="body-text">Quantity:</div>
                                            <div  className ="body-title-2">1</div>
                                        </div>
                                        <div  className ="flex gap10 flex-wrap">
                                                <Link className="tf-button style-1 w230" to="/productlist">View shop</Link>
                                                <Link className="tf-button w230" to="/productlist">View product</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div  className ="wg-box mb-20">
                                <div>
                                    <h6  className ="mb-10">Detail</h6>
                                    <div  className ="body-text">Your items is on the way. Tracking information will be available within 24 hours.</div>
                                </div>
                                <div  className ="road-map">
                                    <div  className ="road-map-item active">
                                        <div  className ="icon"><FaCheck /></div>
                                        <h6 style={{fontSize:"18px", fontWeight:"700"}}>Receiving orders</h6>
                                        <div  className ="body-text">05:43 AM</div>
                                    </div>
                                    <div  className ="road-map-item active">
                                        <div  className ="icon"><FaCheck /></div>
                                        <h6 style={{fontSize:"18px", fontWeight:"700"}}>Order processing</h6>
                                        <div  className ="body-text">01:21 PM</div>
                                    </div>
                                    <div  className ="road-map-item active">
                                        <div  className ="icon"><FaCheck /></div>
                                        <h6 style={{fontSize:"18px", fontWeight:"700"}}>Being delivered</h6>
                                        <div  className ="body-text">Processing</div>
                                    </div>
                                    <div  className ="road-map-item">
                                        <div  className ="icon"><FaCheck /></div>
                                        <h6 style={{fontSize:"18px", fontWeight:"700"}}>Delivered</h6>
                                        <div  className ="body-text">Pending</div>
                                    </div>
                                </div>

                            </div>
                            <div  className ="wg-box">
                                <div  className ="wg-table table-order-track">
                                    <ul  className ="table-title flex mb-24 gap20">
                                        <li>
                                            <div  className ="body-title">Date</div>
                                        </li>    
                                        <li>
                                            <div  className ="body-title">Time</div>
                                        </li>    
                                        <li>
                                            <div  className ="body-title">Description</div>
                                        </li>   
                                        <li>
                                            <div  className ="body-title">Location</div>
                                        </li>   
                                    </ul>
                                    <ul  className ="flex flex-column gap14">
                                        <li  className ="cart-totals-item">
                                            <div  className ="body-text">20 Nov 2023</div>
                                            <div  className ="body-text">2:30 PM</div>
                                            <div  className ="body-text">The sender is preparing the goods</div>
                                            <div  className ="body-text">2715 Ash Dr. San Jose, South Dakota 83475</div>
                                        </li>
                                        <li  className ="divider"></li>
                                        <li  className ="cart-totals-item">
                                            <div  className ="body-text">20 Nov 2023</div>
                                            <div  className ="body-text">01:00 PM</div>
                                            <div  className ="body-text">The order has arrived at the post office</div>
                                            <div  className ="body-text">3517 W. Gray St. Utica, Pennsylvania 57867</div>
                                        </li>
                                        <li  className ="divider"></li>
                                        <li  className ="cart-totals-item">
                                            <div  className ="body-text">21 Nov 2023</div>
                                            <div  className ="body-text">03:58 AM</div>
                                            <div  className ="body-text">The carrier is picking up the goods</div>
                                            <div  className ="body-text">1901 Thornridge Cir. Shiloh, Hawaii 81063</div>
                                        </li>
                                        <li  className ="divider"></li>
                                        <li  className ="cart-totals-item">
                                            <div  className ="body-text">22 Nov 2023</div>
                                            <div  className ="body-text">06:26 PM</div>
                                            <div  className ="body-text">The order has been shipped</div>
                                            <div  className ="body-text">4140 Parker Rd. Allentown, New Mexico 31134</div>
                                        </li>
                                        <li  className ="divider"></li>
                                        <li  className ="cart-totals-item">
                                            <div  className ="body-text">22 Nov 2023</div>
                                            <div  className ="body-text">03:45 PM</div>
                                            <div  className ="body-text">Your order will be delivered to you in 30 minutes</div>
                                            <div  className ="body-text">8502 Preston Rd. Inglewood, Maine 98380</div>
                                        </li>
                                        <li  className ="divider"></li>
                                        <li  className ="cart-totals-item">
                                            <div  className ="body-text">23 Nov 2023</div>
                                            <div  className ="body-text">12:21 AM</div>
                                            <div  className ="body-text">The order has been delivered successfully</div>
                                            <div  className ="body-text">3891 Ranchview Dr. Richardson, California 62639</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  className ="bottom-page">
                        <div  className ="body-text">Copyright © 2024 Aino Tech Global.</div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTracking;
