import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import SideBar from "../component/Sidebar";
import Header from "../component/Header";
import { FaAngleRight } from "react-icons/fa6";
import SweetAlertComponent from "../utils/alert";
import { useAuth } from "../component/AuthContext";
import { Link, useNavigate } from 'react-router-dom';

const CreateRoles = () => {
    const navigate = useNavigate();
    const { setIsSaved } = useAuth();
    const [name, setName] = useState("");
    const [message, setMessage] = useState('');

  const NewRoles = async (e) => {
    let sweetAlertClass = new SweetAlertComponent();
    e.preventDefault();

    const newRoles = { name };

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/admin/create-level', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(newRoles),
      });

      const data = await response.json();

      if (response.ok) {
        setIsSaved(true);
        sweetAlertClass.showSweetAlert(1, "", "New Roles Created");
        navigate('/dashboard/allroles'); 
        setName('');
      } else {
        sweetAlertClass.showSweetAlert(2, "", "Error Creating New Roles");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                    <div className="main-content-inner">
                      <div className="main-content-wrap">
                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                            <h3>Create Role</h3>
                            <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                              
                            </ul>
                        </div>
                        {/* <div className="wg-box">
                            <form className="form-new-product form-style-1" onSubmit={NewRoles}>
                              <div className="wg-box mb-24">
                                <fieldset className="name">
                                  <div className="body-title">Role name <span className="tf-color-1">*</span></div>
                                  <input type="text" placeholder="Role Name" className="" name="name" tabIndex="2" onChange={(e) => setName(e.target.value)} value={name} aria-required="true" required="" />
                                </fieldset>
                                <div className="wg-table table-create-role">
                                    <ul className="table-title flex gap20 mb-3">
                                      <li>
                                        <div className="body-title">Permissions</div>
                                      </li>    
                                    </ul>
                                    <ul className="flex flex-column">
                                        <li className="item gap20 wrap-checkbox">
                                            <div className="body-text">Roles</div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Create</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Edit</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Delete</div></label>
                                            </div>
                                        </li>
                                        <li className="item gap20 wrap-checkbox">
                                            <div className="body-text">Users</div>
                                            <div className="flex items-center gap10">
                                                <input className="total-checkbox" type="checkbox"/>
                                                <label className=""><div className="body-text">All</div></label>
                                            </div>
                                            
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Create</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Edit</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Delete</div></label>
                                            </div>
                                        </li>
                                        <li className="item gap20 wrap-checkbox">
                                            <div className="body-text">Inventory</div>
                                            <div className="flex items-center gap10">
                                                <input className="total-checkbox" type="checkbox"/>
                                                <label className=""><div className="body-text">All</div></label>
                                            </div>
                                            
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Create</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Edit</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Delete</div></label>
                                            </div>
                                        </li>
                                        <li className="item gap20 wrap-checkbox">
                                            <div className="body-text">Aino Banner</div>
                                            <div className="flex items-center gap10">
                                                <input className="total-checkbox" type="checkbox"/>
                                                <label className=""><div className="body-text">All</div></label>
                                            </div>
                                            
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Create</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Edit</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Delete</div></label>
                                            </div>
                                        </li>
                                        <li className="item gap20 wrap-checkbox">
                                            <div className="body-text">Transaction</div>
                                            <div className="flex items-center gap10">
                                                <input className="total-checkbox" type="checkbox"/>
                                                <label className=""><div className="body-text">All</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Create</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Edit</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Delete</div></label>
                                            </div>
                                        </li>
                                        <li className="item gap20 wrap-checkbox">
                                            <div className="body-text">Flash Sales</div>
                                            <div className="flex items-center gap10">
                                                <input className="total-checkbox" type="checkbox"/>
                                                <label className=""><div className="body-text">All</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Index</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Create</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Edit</div></label>
                                            </div>
                                            <div className="flex items-center gap10">
                                                <input className="checkbox-item" type="checkbox"/>
                                                <label className=""><div className="body-text">Delete</div></label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="bot">
                                <div></div>
                                <button className="tf-button w208" type="submit">Save</button>
                                </div>
                              </div>
                            </form>
                        </div> */}

                        <form class="form-create-role" onSubmit={NewRoles}>
                          <div class="wg-box mb-24">
                            <fieldset className="name">
                              <div className="body-title">Role name <span className="tf-color-1">*</span></div>
                              <input type="text" placeholder="Role Name" className="" name="name" tabIndex="2" onChange={(e) => setName(e.target.value)} value={name} aria-required="true" required="" />
                            </fieldset>
                            <div className="wg-table table-create-role">
                              <ul className="table-title flex gap20 mb-3">
                                <li>
                                  <div className="body-title">Permissions</div>
                                </li>    
                              </ul>
                              <ul className="flex flex-column">
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Roles</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Users</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Admin</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                    <div className="body-text">Inventory</div>
                                    
                                    
                                    <div className="flex items-center gap10">
                                        <input className="checkbox-item" type="checkbox"/>
                                        <label className=""><div className="body-text">Create</div></label>
                                    </div>
                                    <div className="flex items-center gap10">
                                        <input className="checkbox-item" type="checkbox"/>
                                        <label className=""><div className="body-text">Edit</div></label>
                                    </div>
                                    <div className="flex items-center gap10">
                                        <input className="checkbox-item" type="checkbox"/>
                                        <label className=""><div className="body-text">Delete</div></label>
                                    </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Aino Banner</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Transaction</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Flash Sales</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Merchant</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Categories</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Sub Categories</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Orders</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Pick Up Stations</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Vouchers</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                                <li className="item gap20 wrap-checkbox">
                                  <div className="body-text">Retailer</div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Create</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Edit</div></label>
                                  </div>
                                  <div className="flex items-center gap10">
                                      <input className="checkbox-item" type="checkbox"/>
                                      <label className=""><div className="body-text">Delete</div></label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="bot">
                              <button class="tf-button w180" type="submit">Save</button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="bottom-page">
                        <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRoles;
