import React, { useState,  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import "../../index.css";
import { FaAngleRight } from "react-icons/fa6";
import { SlCloudUpload } from "react-icons/sl";
import { useAuth } from "../../merchant/component/AuthContext";
import SweetAlertComponent from "../../utils/alert";
import { useNavigate, Link } from 'react-router-dom';
import Utlilty from "../../utils/func";

const EditMerchantProduct = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [keyfeatures, setKeyfeatures] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const { categories, subCategories, vendor, setIsSaved    } = useAuth();
  const utility = new Utlilty();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    quantity: "",
    vendorPrice: "",
    keyfeatures: "",
    category: "",
    subcategory: "",
    minOrderQty: "",
    priceRanges: [{ minQuantity: "", maxQuantity: "", price: "" }],
    vendorPriceRanges: [{ minQuantity: "", maxQuantity: "", price: "" }],
    images: [],
    color: "",
  });
  
  const [priceRanges, setPriceRanges] = useState([
    { minQuantity: '', maxQuantity: '', price: '' }
  ]);

  const [vendorPriceRanges, setVendorPriceRanges] = useState([
    { minQuantity: '', maxQuantity: '', price: '' }
  ]);

  const handlePriceRangeChange = (index, e) => {
    const { name, value } = e.target;
    const newPriceRanges = [...formData.priceRanges];
    newPriceRanges[index][name] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      priceRanges: newPriceRanges,
    }));
  };


  const handleVendorPriceRangeChange = (index, e) => {
    const { name, value } = e.target;
    const newVendorPriceRanges = [...formData.priceRanges];
    newVendorPriceRanges[index][name] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      vendorPriceRanges: newVendorPriceRanges,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddPriceRange = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      priceRanges: [...prevFormData.priceRanges, { minQuantity: "", maxQuantity: "", price: "" }],
    }));
  };

  const handleAddVendorPriceRange = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      vendorPriceRanges: [...prevFormData.vendorPriceRanges, { minQuantity: "", maxQuantity: "", price: "" }],
    }));
  };

  const handleRemovePriceRange = (index) => {
    const newPriceRanges = formData.priceRanges.filter((_, i) => i !== index);
    setFormData((prevFormData) => ({
      ...prevFormData,
      priceRanges: newPriceRanges,
    }));
  };

  const handleRemoveVendorPriceRange = (index) => {
    const newVendorPriceRanges = formData.vendorPriceRanges.filter((_, i) => i !== index);
    setFormData((prevFormData) => ({
      ...prevFormData,
      vendorPriceRanges: newVendorPriceRanges,
    }));
  };

  // Handle file changes for multiple image upload and preview
  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files); 
    const uploadedImages = [];

    for (const file of files) {
      try {
        const imageUrl = await utility.uploadImage(file);
        uploadedImages.push(imageUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      images: [...prevFormData.images, ...uploadedImages],
    }));
  };


  // Remove an image by its index
  const handleRemoveImage = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: prevFormData.images.filter((_, i) => i !== index),
    }));
  };

    // Handle form submission
    const handleSubmit = async (e) => {
      let sweetAlertClass = new SweetAlertComponent();
      e.preventDefault();

      try {
        const vendorToken = localStorage.getItem('vendorToken');
        const response = await fetch('https://aino-tech-server.onrender.com/api/v1/vendor/create-product', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-vendor-token': `${vendorToken}`,
          },
            body: JSON.stringify({
              name: formData.name,
              description: formData.description,
              quantity: formData.quantity,
              price: formData.price,
              keyfeatures: formData.keyfeatures,
              category: formData.category,
              subcategory: formData.subcategory,
              images: formData.images,
              minOrderQty: formData.minOrderQty,
              priceRanges: formData.priceRanges,
              vendorPriceRanges: formData.vendorPriceRanges,
              vendorPrice: formData.vendorPrice,
              color: formData.color
            }),
        });
  
        if (response.ok) {
          const result = await response.json();
          setIsSaved(true);
          sweetAlertClass.showSweetAlert(1, "", "New Product Added, Subjected to Review Before Publish");
          setFormData({name: "", description: "", longDesc: "", quantity: "", price: "", keyfeatures: "", category: "", subcategory: "", images: [], minOrderQty: "", priceRanges: [], vendorPriceRanges: [], vendorPrice: "", color: ""});
          navigate('/merchant/merchantproductlist'); 
          
        } else {
          const errorData = await response.json();
          sweetAlertClass.showSweetAlert(2, "", "Error adding product");
        }
      }  
        catch (error) {
        setError(error.message);
        sweetAlertClass.showSweetAlert(2, "", "Submission failed, please try again.");
      }
    };

    const handleCancelNavigate = () => {
        navigate(`/merchant/merchantproductlist`);
    };

  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <div>
                        <h2>Edit Product</h2>
                        <p>Note that All Merchant Product Uploaded will Be Under Review by the Aino Admin Manager Until be approved</p>
                      </div>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        <li>
                          <Link to="/" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Dashboard</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <Link to="/dashboard/productlist" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Inventory</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <div className="text-tiny">Update product</div>
                        </li>
                      </ul>
                    </div>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <form className="tf-section-2 form-add-product" onSubmit={handleSubmit}>
                        <div className="wg-box w-full">
                            <fieldset className="name">
                                <div className="body-title mb-10">Product name <span className="tf-color-1">*</span></div>
                                <input className="mb-10" type="text" placeholder="Enter product name" name="name" value={formData.name} onChange={handleChange} aria-required="true" required="" />
                            </fieldset>
                            <fieldset className="category">
                                <div className="body-title mb-10">Category <span className="tf-color-1">*</span></div>
                                    <div className="select">
                                        <select name="category" value={formData.category} onChange={handleChange}>
                                        <option>Choose category</option>
                                        {categories?.map((category) => (
                                            <option key={category._id} value={category._id}>
                                            {category.name}
                                            </option>
                                        ))}
                                        </select>
                                    </div>
                            </fieldset>
                            <fieldset className="category">
                                <div className="body-title mb-10">Sub Category <span className="tf-color-1">*</span></div>
                                    <div className="select">
                                        <select name="subcategory" value={formData.subcategory} onChange={handleChange} required >
                                        <option>Choose Sub Category</option>
                                        {subCategories.map((subCategory) => (
                                            <option key={subCategory._id} value={subCategory._id}>
                                            {subCategory.name}
                                            </option>
                                        ))}
                                        </select>
                                    </div>
                            </fieldset>
                            <fieldset className="description">
                                <div className="body-title mb-10">Description <span className="tf-color-1">*</span></div>
                                <textarea className="mb-10" placeholder="Description" name="description" value={formData.description} onChange={handleChange}></textarea>
                            </fieldset>
                            <div className="cols gap22">
                            <fieldset className="name">
                                <div className="body-title mb-10">Quantity Available <span className="tf-color-1">*</span></div>
                                <input className="mb-10" type="text" placeholder="Enter Available Quantity" name="quantity" value={formData.quantity} onChange={handleChange} />
                            </fieldset>
                            <fieldset className="name">
                                <div className="body-title mb-10">Vendor Price <span className="tf-color-1">*</span></div>
                                <input className="mb-10" type="number" placeholder="Enter Price Vendor" name="vendorPrice" value={formData.vendorPrice} onChange={handleChange} />
                            </fieldset>
                            
                            </div>
                            <fieldset>
                                <div className="body-title mb-10">Upload images</div>
                                <div className="upload-image mb-16">
                                    {formData.images.length === 0 ? (
                                    <div className="item">
                                        <label className="uploadfile" htmlFor="myFile">
                                        <span className="icon">
                                            <SlCloudUpload color="blue" size={40} />
                                        </span>
                                        <span className="text-tiny">
                                            Drop your images here or select{" "}
                                            <span className="tf-color">click to browse</span>
                                        </span>
                                        <input type="file" id="myFile" name="filename" accept="image/*" onChange={handleFileChange} multiple
                                        />
                                        </label>
                                    </div>
                                    ) : (
                                    formData.images.map((image, index) => (
                                        <div key={index} className="item preview-container">
                                        <img src={image} alt={`preview-${index}`} className="preview-image" />
                                        <button className="close-button" onClick={() => handleRemoveImage(index)} > &times;
                                        </button>
                                        </div>
                                    ))
                                    )}
                                </div>
                                <div className="body-text">
                                    You need to add at least 2 images. Pay attention to the quality of the pictures you add, comply with the background color standards. Pictures must be in certain dimensions. Notice that the product shows all the details.
                                </div>
                            </fieldset>
                            <div className="cols gap10">
                                <button className="tf-button w-full" type="submit">
                                    Update product
                                </button>
                                <button className="tf-button w-full" type="submit" onClick={() => {  handleCancelNavigate()}}>
                                    Cancel Update
                                </button>
                            </div>
                        </div>
                    </form>
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMerchantProduct;
