import React, { useState } from "react";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../component/AuthContext";
import { Link } from "react-router-dom";
import NoAdminImage from '../../images/logo/logo.png';
import { useNavigate } from "react-router-dom";
import SweetAlertComponent from "../../utils/alert";
import { RxEyeOpen } from "react-icons/rx";
import { GoEyeClosed } from "react-icons/go";

const ChangePassword = () => {
    const { error } = useAuth();
    const navigate = useNavigate();
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [newPassword, setPassword] = useState("");
    const [apiError, setApiError] = useState(null); // Store API error if any
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const [showPassword, setShowPassword] = useState(false);
    const [email , setEmail] = useState("");

    const handleChange = (element, index) => {
        const value = element.target.value;
        if (/^[0-9]$/.test(value) || value === "") {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value !== "" && index < otp.length - 1) {
                document.getElementById(`otp-${index + 1}`).focus();
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prev => !prev); // Toggle password visibility
    };

    const changePassword = async (otpCode, newPassword, email) => {
        let sweetAlertClass = new SweetAlertComponent();
        setIsLoading(true);
        try {
            const response = await fetch("https://aino-tech-server.onrender.com/api/v1/vendor/reset-password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ 
                    otp: otpCode,
                    newPassword: newPassword,
                    email: email

                }),
            });

            if (!response.ok) {
                const errorData = await response.json(); // Capture error message from response
                throw new Error(errorData.message || "Password change failed. Please try again.");
            }
            const data = await response.json();
            sweetAlertClass.showSweetAlert(1, "Success", data.message);
            navigate("/merchantlogin"); // Redirect on success
        } catch (error) {
            console.error("Error during Password Changing verification:", error);
            sweetAlertClass.showSweetAlert(2, "Error", error.message);
            setApiError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const otpCode = otp.join("");
        changePassword(otpCode, newPassword, email);
    };

    return (
        <div className="container">
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex justify-content-center py-4">
                                <Link to="" className="logo d-flex align-items-center w-auto">
                                    <img src={NoAdminImage} alt="" className="large-image" />
                                </Link>
                            </div>

                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="pt-4 pb-2">
                                        <p className="text-center run">Enter the OTP sent to your email and Change your Password</p>
                                    </div>
                                    {Array.isArray(error) && error.length > 0 && (
                                        <div className="alert alert-danger">
                                            <ul>
                                                {error.map((err, index) => (
                                                    <li key={index}>{err.msg}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    {apiError && <div className="alert alert-danger">{apiError}</div>}
                                    <form onSubmit={onSubmit} className="row g-3 text-center">

                                        <div className="col-12">
                                            <label htmlFor="email" className="form-label runs">Merchant Email</label>
                                            <input type="email" name="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                        </div>

                                        <div className="col-12 position-relative">
                                            <label htmlFor="email" className="form-label runs">One-time password (OTP) </label>
                                            <div className="col-12 d-flex justify-content-center gap-2">
                                                {otp.map((value, index) => (
                                                    <input key={index} id={`otp-${index}`} type="text" maxLength="1" value={value} onChange={(e) => handleChange(e, index)} className="form-control otp-input" style={{ width: "70px", height: "70px", fontSize: "2rem", textAlign: "center" }} />
                                                ))}
                                            </div>
                                        </div>

                                        <div className="col-12 position-relative">
                                            <label htmlFor="password" className="form-label runs">New Password</label>
                                            <input type={showPassword ? "text" : "password"} name="newPassword" className="form-control" id="yourPassword" onChange={(e) => setPassword(e.target.value)} value={newPassword} required />
                                            <span className="password-toggle-icon" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '20px', top: '40px', cursor: 'pointer', fontSize: '1.9rem'}}>
                                                {showPassword ? <GoEyeClosed /> : <RxEyeOpen />}
                                            </span>
                                        </div>

                                        <div className="col-12">
                                            <button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
                                                {isLoading ? "Changing Password..." : "Change Password"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="credits">Developed by <Link to="">Aino-Tech Global</Link> </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ChangePassword;
