import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import LoadingGif from '../images/avatar/aino_loading.gif';

const UserDetails = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [userdetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const { userId } = useParams();

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {
        if (userId) {
            getUserDetails(userId);
        }
    }, [userId]);

    // User Full Details
    const getUserDetails = async (userId) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/users/get-user-full-details`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user details');
            }

            const data = await response.json();
            setUserDetails(data.user);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user details:', error.message);
            setLoading(false);
        }
    };

    if (loading || !userdetails) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '150px' }}>
                <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                <p>Loading User Details...</p>
            </div>
        );
    }

    function formatPrice(price) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const { userDetails, orders } = userdetails;

    return (
        <div>
            <div id="wrapper">
                <div id="page">
                    <div className="layout-wrap">
                        <div className="section-content-right">
                            <div className="main-content">
                                <div className="main-content-inner">
                                    <div className="main-content-wrap">
                                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                            <div>

                                                <h3>User Details: {userDetails.firstName} {userDetails.lastName}</h3>
                                                <h3>User ID: {userDetails._id}</h3>
                                            </div>
                                            <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                                                <li>
                                                    <Link to="/" style={{ textDecoration: "none" }}>
                                                        <div className="text-tiny">Dashboard</div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <FaAngleRight />
                                                </li>
                                                <li>
                                                    <Link to="/dashboard/user" style={{ textDecoration: "none" }}>
                                                        <div className="text-tiny">User List</div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <FaAngleRight />
                                                </li>
                                                <li>
                                                    <Link to="#" style={{ textDecoration: "none" }}>
                                                        <div className="text-tiny">User Details</div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="widget-tabs">
                                            <div className="widget-content-tab">
                                                <div className={`widget-content-inner ${activeTab === 0 ? 'active' : ''}`}>
                                                    <h4>User Details</h4>
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>Name</td>
                                                                <td>{userDetails.firstName} {userDetails.lastName}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Email</th>
                                                                <td>{userDetails.email}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Date of Birth</th>
                                                                <td>{new Date(userDetails.dob).toLocaleDateString()}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Gender</th>
                                                                <td>{userDetails.gender}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <h4>Addresses</h4>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Street</th>
                                                                <th>City</th>
                                                                <th>State</th>
                                                                <th>Phone Number</th>
                                                                <th>Active</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {userDetails.addresses.map(address => (
                                                                <tr key={address._id}>
                                                                    <td>{address.streetAdd}</td>
                                                                    <td>{address.city}</td>
                                                                    <td>{address.state}</td>
                                                                    <td>{address.phoneNumber}</td>
                                                                    <td>{address.active ? 'Yes' : 'No'}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className={`widget-content-inner ${activeTab === 1 ? 'active' : ''}`}>
                                                    <h4>User Orders</h4>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Order ID</th>
                                                                <th>Total Amount</th>
                                                                <th>Payment Method</th>
                                                                <th>Delivery Option</th>
                                                                <th>Status</th>
                                                                <th>Order Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {orders.map(order => (
                                                                <tr key={order._id}>
                                                                    <td>{order._id}</td>
                                                                    <td> &#8358; {formatPrice(order.totalAmount)} </td>
                                                                    <td>{order.paymentMethod}</td>
                                                                    <td>{order.deliveryOption}</td>
                                                                    <td>{order.status}</td>
                                                                    <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-page">
                                        <div className="body-text">
                                            Copyright © 2024 Aino Tech Global.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDetails;
