import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { IoEyeOutline } from "react-icons/io5";
import { FaAngleRight, FaCheckDouble } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import SweetAlertComponent from "../utils/alert";
import NoAdminImage from '../images/avatar/vendor.jpg';
import LoadingGif from '../images/avatar/aino_loading.gif';
import { useAuth } from "../component/AuthContext";
import user5 from "../images/avatar/merchant.jpg";
import Utility from "../utils/func";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Vendor = () => {
  const { vendor, getVendor } = useAuth();
  const [vendorList, setVendorList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const utility = new Utility();

  useEffect(() => {
    const fetchVendors = async () => {
      setLoading(true);
      await getVendor(currentPage);
      setLoading(false);
    };
    fetchVendors();
  }, [currentPage]);

  useEffect(() => {
    setVendorList(vendor);
  }, [vendor]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleViewVendor = (vendor) => {
    setSelectedVendor(vendor);
    setShowModal(true);
  };

  const handleVerifyMerchant = async (merchantId) => {
    let sweetAlertClass = new SweetAlertComponent();
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/verify-vendor/${merchantId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const result = await response.json();
      if (response.ok) {
        sweetAlertClass.showSweetAlert(1, "", `Verification Status: ${result.message}`);
        
        // Update the vendor list in the state
        setVendorList((prevList) => 
          prevList.map((vendor) => 
            vendor._id === merchantId ? { ...vendor, accountStatus: "active" } : vendor
          )
        );
      } else {
        sweetAlertClass.showSweetAlert(2, "", `Verification failed: ${result.message}`);
      }
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "", `There was an error verifying the merchant.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '150px' }}>
          <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
          <p>All Merchant Loading...</p>
        </div>
      ) : (
        <div id="wrapper">
          <div id="page" className="">
            <div className="layout-wrap">
              <div className="section-content-right">
                <div className="main-content">
                  <div className="main-content-inner">
                    <div className="main-content-wrap">
                      <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                        <h3>Below is A List of All Merchant with 20 Merchant Per Page</h3>
                        <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                          
                        </ul>
                      </div>
                      <div className="wg-box">
                        <div className="wg-table table-all-user">
                          <ul className="table-title flex gap20 mb-14">
                            <li><div className="body-title">Merchant Name</div></li>
                            <li><div className="body-title">Merchant Email</div></li>
                            <li><div className="body-title">Account Status</div></li>
                            {/* <li><div className="body-title">Account Verification</div></li> */}
                            <li><div className="body-title">Account Created</div></li>
                            <li><div className="body-title">Action</div></li>
                          </ul>
                          <ul className="flex flex-column">
                            {vendorList.map((vendors, index) => (
                              <li className="user-item gap14" key={vendors._id}>
                                <div className="body-text">{index + 1}</div>
                                <div className="image"><img src={user5} alt="" className="" /></div>
                                <div className="flex items-center justify-between gap20 flex-grow">
                                  <div className="body-text">{vendors.storeName}</div>
                                  <div className="body-text">{vendors.email}</div>
                                  <div className={`body-text ${vendors.accountStatus === "active" ? "block-available" : "block-not-available"}`}>
                                    {vendors.accountStatus === "active" ? "Active" : "Unverified"}
                                  </div>
                                  {/* <div className="body-text">{vendors.isVerified ? "true" : "false"}</div> */}
                                  <div className="body-text">{utility.convertCreatedTime(vendors.createdAt)}</div>
                                  <div className="list-icon-function">
                                    {vendors.accountStatus !== "active" && (
                                      <div className="item edit">
                                        <FaCheckDouble color="green" onClick={() => handleVerifyMerchant(vendors._id)} />
                                      </div>
                                    )}
                                    <div className="item trash"><IoEyeOutline color="blue" onClick={() => handleViewVendor(vendors)} /></div>
                                  </div>
                                </div>
                              </li>
                            ))}
                            {vendorList.length === 0 && (
                              <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                <img src={NoAdminImage} alt="No Product Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                                <h2>No Merchant found.</h2>
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="divider"></div>
                        <div className="flex items-center justify-between flex-wrap gap10">
                          <div className="body-text"></div>
                          <ul className="wg-pagination">
                            <li><Link to="#" onClick={handlePreviousPage} style={{ textDecoration: 'none' }}><FaChevronLeft /></Link></li>
                            {[...Array(totalPages)].map((_, index) => (
                              <li key={index}>
                                <Link to="#" onClick={() => setCurrentPage(index + 1)} style={{ textDecoration: 'none', fontWeight: currentPage === index + 1 ? 'bold' : 'normal' }}>
                                  {index + 1}
                                </Link>
                              </li>
                            ))}
                            <li><Link to="#" onClick={handleNextPage} style={{ textDecoration: 'none' }}><FaAngleRight /></Link></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-page">
                    <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Vendor Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedVendor && (
            <div>
              <p><strong>Name:</strong> {selectedVendor.firstName} {selectedVendor.lastName}</p>
              <p><strong>Email:</strong> {selectedVendor.email}</p>
              <p><strong>Store Name:</strong> {selectedVendor.storeName}</p>
              <p><strong>Account Status:</strong> {selectedVendor.accountStatus}</p>
              <p><strong>Ref Code:</strong> {selectedVendor.refCode}</p>
              <p><strong>Created At:</strong> {utility.convertCreatedTime(selectedVendor.createdAt)}</p>
              <h5>Bank Details:</h5>
              {selectedVendor.bankDetails.map((bank, idx) => (
                <div key={bank._id}>
                  <p><strong>Bank Name:</strong> {bank.bankName}</p>
                  <p><strong>Account Number:</strong> {bank.accountNumber}</p>
                  <p><strong>Account Name:</strong> {bank.accountName}</p>
                  <p><strong>ID Card:</strong> {bank.idCard ? <img src={bank.idCard} alt="ID Card" /> : "No image available"}</p>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default Vendor;
