import React, { useState } from "react";
import logo from "../../images/logo/Aino Logo-07.jpg";
import NoAdminImage from '../../images/avatar/avatar.png';
import { useAuth } from "../../merchant/component/AuthContext";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { FaList } from "react-icons/fa6";
import { PiListChecksBold } from "react-icons/pi";

const Header = () => {
    const { merchantlogout } = useAuth();
    const { profile  } = useAuth();

    // State to manage sidebar toggle
    const [isSidebarToggled, setIsSidebarToggled] = useState(false);

    // Toggle sidebar function
    const handleToggleSidebar = () => {
        setIsSidebarToggled(!isSidebarToggled);
        document.body.classList.toggle('toggle-sidebar');
    };
    
  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
            <Link to="#" className="logo d-flex align-items-center">
                <img src={logo}  alt="Logo" />
                <span class="d-none d-lg-block">Aino-Tech</span>
            </Link>
            <div className="toggle-sidebar-btn" onClick={handleToggleSidebar}>
                <FaList />
            </div>
        </div>

        <nav className="header-nav ms-auto">
            <ul className="d-flex align-items-center">
                <li className="nav-item dropdown">

                </li>

                <li className="nav-item dropdown">

                </li>

                <li className="nav-item dropdown pe-3">
                    <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="" data-bs-toggle="dropdown">
                        <img src={profile?.profileImg || NoAdminImage} alt={profile.firstName} className="rounded-circle"/>
                    <span className="d-none d-md-block dropdown-toggle ps-2">{profile?.firstName} {profile?.lastName}</span>
                    </Link>

                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li className="dropdown-header">
                            <h6>{profile?.firstName} {profile?.lastName}</h6>
                            <span>{profile?.storeName || ""}</span>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>

                        <li>
                            <Link className="dropdown-item d-flex align-items-center" to="/merchant/merchantdetails">
                                <i className="bi bi-person"></i>
                            <span>Merchant Profile</span>
                            </Link>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>

                        <li>
                            <Link onClick={() => merchantlogout()} className="dropdown-item d-flex align-items-center" to="">
                            <i className="bi bi-box-arrow-right"></i>
                            <span>Log Out</span>
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>

    </header>
  );
};

export default Header;
