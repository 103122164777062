import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaAngleRight } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "../component/AuthContext";
import SweetAlertComponent from "../utils/alert";


const NewDiscount = () => {
    const navigate = useNavigate();
    const { setIsSaved } = useAuth();
    const [formData, setFormData] = useState({
        code: "",
        discountPercentage: "",
        type: "discount"
    });

    const [name , setName] = useState('');
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
    const NewVoucher = async (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        e.preventDefault();

        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        };

        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://aino-tech-server.onrender.com/api/v1/vouchers/discount', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({
                    code: formData.code,
                    discountPercentage: formData.discountPercentage,
                    type: formData.type
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setIsSaved(true);
                sweetAlertClass.showSweetAlert(1, "", "New Discount Code Created");
                navigate('/dashboard/voucherlist');
                setName('');
            } else {
                sweetAlertClass.showSweetAlert(2, "", "Error Creating New Discount Code");
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div>
            <div id="wrapper">
                <div id="page" className="">
                    <div className="layout-wrap">
                        <div className="section-content-right">
                            <div className="main-content">
                                <div className="main-content-inner">
                                    <div className="main-content-wrap">
                                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                            <h3>Discount Code infomation</h3>
                                            <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                                                
                                            </ul>
                                        </div>
                                        <div className="wg-box">
                                            <form className="form-new-product form-style-1" onSubmit={NewVoucher}>
                                                <fieldset className="name">
                                                    <div className="body-title">Discount Code <span className="tf-color-1">*</span></div>
                                                    <input type="text" placeholder="Discount Code" className="" name="code" tabIndex="2" onChange={(e) => setFormData({ ...formData, code: e.target.value })} value={formData.code} aria-required="true" required="" />
                                                </fieldset>
                                                <fieldset className="name">
                                                    <div className="body-title">Code Percentage <span className="tf-color-1">*</span></div>
                                                    <input type="number" placeholder="Discount Code Percentage" className="" name="discountPercentage" tabIndex="2" onChange={(e) => setFormData({ ...formData, discountPercentage: e.target.value })} value={formData.discountPercentage} aria-required="true" required="" />
                                                </fieldset>
                                                <div className="bot">
                                                    <div></div>
                                                    <button className="tf-button w208" type="submit">Save</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-page">
                                    <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewDiscount;
