import React from "react";
import { Link, Navigate } from 'react-router-dom';
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import user5 from "../images/avatar/admin.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FiChevronRight, FiChevronLeft, FiDollarSign } from "react-icons/fi";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { CiFileOn } from "react-icons/ci";
import { LuUsers } from "react-icons/lu";
import { useAuth } from "../component/AuthContext";
import { useState } from "react";

const Index = () => {

  const { dashboard = {} } = useAuth();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  function formatPrice(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div id="wrapper">
      <div id="page" className="">
        <div className="layout-wrap">
          <div className="section-content-right">
            <div className="main-content">
              <div className="main-content-inner">
                <div className="main-content-wrap">
                  <div className="tf-section-4 mb-30">
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon">
                            <HiOutlineShoppingBag color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Total Sales In Number</div>
                            <h4>{dashboard.totalSales}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon-4">
                            <LuUsers color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Total Sales In Amount</div>
                            <h4>&#x20A6;{dashboard?.todayOrders?.value ?? 0}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon-3">
                            <CiFileOn color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Today's Order In Number</div>
                            <h4>{dashboard?.todayOrders?.quantity ?? 0}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon">
                            <FiChevronLeft color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Today's Sales In Number</div>
                            <h4>{dashboard.todaySales}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon">
                            <FiChevronRight color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Last Week Sales In Number</div>
                            <h4>{dashboard?.lastWeekSales?.quantity ?? 0}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon">
                            <FiChevronLeft color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Last Week Sales In Amount</div>
                            <h4>&#x20A6; {dashboard?.lastWeekSales?.value ?? 0}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon">
                            <HiOutlineShoppingBag color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Last Month Sales In Amount</div>
                            <h4>&#x20A6; {dashboard?.lastMonthSales?.value ?? 0}</h4>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon">
                            <FiChevronLeft color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Last Month Sales In Number</div>
                            <h4>{dashboard?.lastMonthSales?.quantity ?? 0}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon">
                            <FiChevronRight color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Last Week Order In Number</div>
                            <h4>{dashboard?.lastWeekOrders?.quantity ?? 0}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon">
                            <HiOutlineShoppingBag color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Last Week Order In Amount</div>
                            <h4> &#x20A6; {dashboard?.lastWeekOrders?.value ?? 0}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon">
                            <FiChevronLeft color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Last Month Sales In Number</div>
                            <h4>{dashboard?.lastMonthSales?.quantity ?? 0}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon">
                            <FiChevronRight color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Last Month Sales In Amount</div>
                            <h4> &#x20A6; {dashboard?.lastMonthSales?.value ?? 0}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon">
                            <HiOutlineShoppingBag color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Last Month Order In Number</div>
                            <h4>{dashboard?.lastMonthOrders?.quantity ?? 0}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon">
                            <FiChevronRight color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Last Month Order In Amount</div>
                            <h4> &#x20A6; {dashboard?.lastMonthOrders?.value ?? 0}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="wg-chart-default">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap14">
                          <div className="hexagon-2">
                            <FiDollarSign color="white" size={10} />
                          </div>
                          <div>
                            <div className="body-text mb-2">Total Users</div>
                            <h4>{dashboard.totalUsers} Users</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tf-section-6 mb-30">
                    <div className="wg-box">
                      <div className="flex items-center justify-between">
                        <h2>Top Products</h2>
                      </div>
                      <div className="wg-table table-top-product">
                      <ul className="table-title flex gap10 mb-14">
                          <li>
                            <div className="body-title">Product Name</div>
                          </li>
                          <li>
                            <div className="body-title">Price</div>
                          </li>
                          <li>
                            <div className="body-title">Quantity Sold</div>
                          </li>
                          <li>
                            <div className="body-title"></div>
                          </li>
                          
                        </ul>
                        <ul className="flex flex-column gap14">
                        {dashboard.topProducts && Array.isArray(dashboard.topProducts) && dashboard.topProducts.map((item, index) => (
                          <li key={index} className="product-item gap14">
                            <div className="image small">
                              <img src={item.images[0]} alt={item.name} className="" />
                            </div>
                            <div className="flex items-center justify-between flex-grow gap10">
                              <div className="name">
                                <Link to="" className="body-text">
                                  {item.name}
                                </Link>
                              </div>
                              <div className="body-text">&#8358; {formatPrice(item.price)}</div>
                              <div className="body-text"> </div>
                              <div className="body-text"> </div>
                            </div>
                          </li>
                        ))}
                        </ul>
                      </div>
                      <div className="divider"></div>
                      <div className="flex items-center justify-between flex-wrap gap10">
                        <div className="text-tiny">Showing 5 Latest entries</div>
                      </div>
                    </div>
                  </div>

                  {/* <div className=" tf-section-6 mb-30">
                    <div className="wg-box">
                      <div className="flex items-center justify-between">
                        <h2>Top Selling Vendor</h2>
                      </div>
                      <div className="wg-table table-best-shop-sellers">
                        <ul className="table-title flex gap20 mb-14">
                          <li>
                            <div className="body-title">Shop</div>
                          </li>
                          <li>
                            <div className="body-title">Categories</div>
                          </li>
                          <li>
                            <div className="body-title">Total</div>
                          </li>
                          <li>
                            <div className="body-title">Status</div>
                          </li>
                        </ul>
                        <ul className="flex flex-column gap18">
                          <li className="shop-item">
                            <div className="image">
                              <img src={user5} alt="" className="" />
                            </div>
                            <div className="flex-grow flex items-center justify-between gap20">
                              <div>
                                <Link to="" className="body-text name">
                                  Jorge
                                </Link>
                                <div className="text-tiny mt-4">
                                  30 Purchases
                                </div>
                              </div>
                              <div className="body-text">Toys, Baby</div>
                              <div className="body-text">$4,750</div>
                              <div className="flex items-center justify-between gap10">
                                <div className="progress-level-bar t4">
                                  <span
                                    data-progress="23"
                                    data-max="70"
                                    className=""
                                  ></span>
                                </div>
                                <div className="text-tiny">100%</div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="divider"></div>
                      <div className="flex items-center justify-between flex-wrap gap10">
                        <div className="text-tiny">Showing 5 Latest entries</div>
                      </div>
                    </div>
                  </div> */}
                  <div className="tf-section-6 mb-30">
                    <div className="wg-box">
                      <div className="flex items-center justify-between">
                        <h2>Total Orders</h2>
                      </div>
                      <div className="wg-table table-product-overview">
                        <ul className="table-title flex gap20 mb-14">
                          <li>
                            <div className="body-title">User Name</div>
                          </li>
                          <li>
                            <div className="body-title">Product ID</div>
                          </li>
                          <li>
                            <div className="body-title">Price</div>
                          </li>
                          <li>
                            <div className="body-title">Quantity</div>
                          </li>
                          <li>
                            <div className="body-title">totalAmount</div>
                          </li>
                          <li>
                            <div className="body-title">Status</div>
                          </li>
                        </ul>
                        <ul className="flex flex-column gap10">
                          {dashboard.topOrders && Array.isArray(dashboard.topOrders) && dashboard.topOrders.map((item, index) => (
                            <li key={index} className="product-item gap14">
                              <div className="flex items-center justify-between flex-grow gap10">
                                <div className="name">
                                  <Link to="" className="body-text">
                                    {item.user.firstName} {item.user.lastName}
                                  </Link>
                                  <div className="text-tiny mt-4">
                                    {item.user.email}
                                  </div>
                                </div>
                                <div className="order-details">
                                  {item.products && Array.isArray(item.products) && item.products.map((product, productIndex) => (
                                    <div key={productIndex} className="product-info flex items-center justify-between flex-grow gap10 mt-2">
                                      <div className="name">
                                        <Link to="" className="body-text">
                                          {product.product}
                                        </Link>
                                      </div>
                                      <div className="body-text">
                                         &#8358; {formatPrice(product.priceAtPurchase)}
                                      </div>
                                      <div className="body-text">
                                      {product.quantity}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div className="total-amount">
                                  <div className="body-text">
                                     &#8358; {formatPrice(item.totalAmount)}
                                  </div>
                                </div>
                                <div className="order-status">
                                  <div className="block-available">
                                    {item.status}
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="divider"></div>
                      <div className="flex items-center justify-between flex-wrap gap10">
                        <div className="text-tiny">Showing 5 Latest entries</div>
                        
                      </div>
                    </div>
                  </div>

                  <div className="tf-section-6">
                    <div className="wg-box">
                      <div className="flex items-center justify-between">
                        <h2>Recent Products Upload</h2>
                      </div>
                      <div className="wg-table table-orders">
                        <ul className="table-title flex gap10 mb-14">
                          <li>
                            <div className="body-title">Product Name</div>
                          </li>
                          <li>
                            <div className="body-title">Price</div>
                          </li>
                          <li>
                            <div className="body-title">Category</div>
                          </li>
                          <li>
                            <div className="body-title">Vendor Name</div>
                          </li>
                        </ul>
                        <ul className="flex flex-column gap18">
                        {dashboard.recentProducts && Array.isArray(dashboard.recentProducts) && dashboard.recentProducts.map((item, index) => (
                          <li key={index} className="product-item gap14">
                            <div className="image small">
                              <img src={item.images[0]} alt={item.name} className="" />
                            </div>
                            <div className="flex items-center justify-between flex-grow gap10">
                              <div className="name">
                                <Link to="" className="body-text">
                                  {item.name}
                                </Link>
                              </div>
                              <div className="body-text">&#8358; {formatPrice(item.price)}</div>
                              <div className="body-text"> {item.category ? item.category.name : ""} </div>
                              <div className="body-text"> {item.wholesaler ? item.wholesaler.storeName : ""} </div>
                            </div>
                          </li>
                        ))}
                        </ul>
                      </div>
                      <div className="divider"></div>
                      <div className="flex items-center justify-between flex-wrap gap10">
                        <div className="text-tiny">Showing 5 Latest entries</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-page">
                <div className="body-text">
                  Copyright © 2024 Aino Tech Global.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
