import "./App.css";
import React from "react";
import { createBrowserRouter, RouterProvider, } from 'react-router-dom';
import Login from "./pages/Login";
import Index from "./pages/Index";
import User from "./pages/User";
import Vendor from "./pages/Vendor";
import ProductList from "./pages/ProductList";
import AddProduct from "./pages/AddProduct";
import AllRoles from "./pages/AllRoles";
import CreateRoles from "./pages/CreateRoles";
import OrderList from "./pages/OrderList";
import OrderDetails from "./pages/OrderDetails";
import OrderTracking from "./pages/OrderTracking";
import CategoriesList from "./pages/CategoriesList";
import NewCategories from "./pages/NewCategories";
import EditCategories from "./pages/EditCategories";
import { AuthProvider } from "./component/AuthContext";
import Admin from "./pages/Admin";
import AddAdmin from "./pages/AddAdmin";
import EditAdmin from "./pages/EditAdmin";
import SubCategories from "./pages/SubCategories";
import NewSubCategories from "./pages/NewSubCategories";
import DashboardLayout from "./pages/DashboardLayout";
import PrivateRoute from "./component/PrivateRoute";
import NewDiscount from "./pages/NewDiscount";
import PickUpStationList from "./pages/PickUpStationList";
import NewPickUpList from "./pages/NewPickUpList";
import NewPromo from "./pages/NewPromo";
import VoucherList from "./pages/VoucherList";
import NewVoucher from "./pages/NewVoucher";
import UserDetails from "./pages/UserDetails";
import FlashSalesList from "./pages/FlashSales";
import AddFlashSales from "./pages/AddFlashSales";
import TransactionList from "./pages/TranscationList";
import EditPickUpList from "./pages/EditPickUpList";
import Influencer from "./pages/Influencer";
import InfluencerSignIn from "./pages/InfluencerSignIn";
import Advert from "./pages/Advert";
import AddAdvert from "./pages/AddAdvert";
import InfluencerLogin from "./pages/InfluencerLogin";
import AddVendor from "./pages/AddVendor";
import MerchantSignUp from "./merchant/pages/MerchantSignUp";
import MerchantLogin from "./merchant/pages/MerchantLogin";
import MerchantDashBoard from "./merchant/pages/MerchantDashBoard";
import MerchantLayout from "./merchant/pages/MerchantLayout";
import EditProduct from "./pages/EditProduct";
import MerchantDetails from "./merchant/pages/MerchantDetails";
import MerchantProductList from "./merchant/pages/MerchantProductList";
import MerchantBank from "./merchant/pages/MerchantBank";
import AddMerchantBank from "./merchant/pages/AddMerchantBank";
import AddMerchantProduct from "./merchant/pages/AddMerchantProduct";
import EditMerchantProduct from "./merchant/pages/EditMerchantProduct";
import Retailer from "./pages/Retailer";
import VerifyEmail from "./merchant/pages/VerifyEmail";
import MerchantOrder from "./merchant/pages/MerchantOrder";
import ForgetPassword from "./merchant/pages/ForgetPassword";
import ChangePassword from "./merchant/pages/ChangePassword";
import Referrals from "./pages/Referrals";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
  },
  {
    path : 'influencerSignIn',
    element : <InfluencerSignIn />
  },
  {
    path: 'influencerLogin',
    element: <InfluencerLogin />
  },
  {
    path: 'merchantsignup',
    element: <MerchantSignUp />
  },
  {
    path: 'merchantlogin',
    element: <MerchantLogin />
  },
  {
    path: 'verifyemail',
    element: <VerifyEmail />
  },
  {
    path: 'forgetPassword',
    element: <ForgetPassword />
  },
  {
    path: 'changePassword',
    element: <ChangePassword />
  },
  {
    path : "/merchant",
    element: < MerchantLayout/>,
    children:[
      {
        path: 'merchantdashboard',
        element: <MerchantDashBoard />
      },
      {
        path: 'merchantdetails',
        element: <MerchantDetails />
      },
      {
        path: 'merchantproductlist',
        element: <MerchantProductList />
      },
      {
        path: 'merchantbank',
        element: <MerchantBank />
      },
      {
        path: 'addmerchantbank',
        element: <AddMerchantBank />
      }, 
      {
        path: 'addmerchantproduct',
        element: <AddMerchantProduct />
      }, 
      {
        path: 'editmerchantproduct/:id',
        element: <EditMerchantProduct />
      }, 
      {
        path: 'merchantorder', 
        element: <MerchantOrder />
      }
    ],

  },

  {
    path: '/dashboard',
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Index />
      },
      {
        path: 'catergorieslist',
        element: <CategoriesList />
      },
      {
        path: 'advert',
        element: <Advert />
      },
      {
        path: 'addadvert',
        element: <AddAdvert />
      },
      {
        path: 'influencer', 
        element: <Influencer />
      },
      {
        path: 'admin',
        element: <Admin />
      },
      {
        path: 'addadmin',
        element: <AddAdmin />
      },
      {
        path: 'editadmin/:id',
        element: <EditAdmin />
      },
      {
        path: 'user',
        element: <User />
      },
      {
        path: 'merchant',
        element: <Vendor />
      },
      {
        path: 'addmerchant',
        element: <AddVendor />
      },
      
      {
        path: 'newcategories',
        element: <NewCategories />
      },
      {
        path: 'subcategories',
        element: <SubCategories />
      },
      {
        path: 'newsubcategories',
        element: <NewSubCategories />
      },
      {
        path: 'allroles',
        element: <AllRoles />
      },
      {
        path: 'createroles',
        element: <CreateRoles />
      },
      {
        path: 'productlist',
        element: <ProductList />
      },
      {
        path: 'addproduct',
        element: <AddProduct />
      }, 
      
      {
        path: 'flashsales',
        element: <FlashSalesList />
      },
      {
        path: 'addFlashSales',
        element: <AddFlashSales />
      },
      {
        path: 'newdiscount',
        element: <NewDiscount />
      },
      {
        path: 'orderlist',
        element: <OrderList />
      },
      {
        path: 'orderdetails/:orderId',
        element: <OrderDetails />
      }, 
      {
        path: 'transcationlist',
        element: <TransactionList />
      },
      {
        path: 'ordertracking/:orderId',
        element: <OrderTracking />
      },
      {
        path: 'pickupstationlist',
        element: <PickUpStationList />
      },
      {
        path: 'newpickuplist',
        element: <NewPickUpList />
      },
      {
        path: 'editpickuplist/:locationId',
        element: <EditPickUpList />
      },
      {
        path: 'pickupstationlist',
        element: <PickUpStationList />
      },
      {
        path: 'editcategories',
        element: <EditCategories />
      },
      {
        path: 'newpromo',
        element: <NewPromo />
      },
      {
        path: 'voucherlist',
        element: <VoucherList />
      },
      {
        path: 'newvoucher',
        element: <NewVoucher />
      },
      {
        path: 'userdetails/:userId',
        element: <UserDetails />
      },
      {
        path: 'editproduct/:productId',
        element: <EditProduct />
      },
      {
        path: 'retailer',
        element: <Retailer />
      }, 
      {
        path: 'referrals',
        element: <Referrals />
      }

      // Add more nested routes here if needed
    ],
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
