import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { FaAngleRight, FaPlus, FaRegTrashCan, FaCopy } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { useNavigate, Link } from "react-router-dom";
import SweetAlertComponent from "../utils/alert";
import { useAuth } from "../component/AuthContext";
import NoAdminImage from '../images/avatar/admin.png';
import LoadingGif from '../images/avatar/aino_loading.gif';
import Utiliiy from "../utils/func";

const Admin = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { admin, getAdmins } = useAuth();
  const utility = new Utiliiy();

  useEffect(() => {
    const fetchAdmin = async () => {
      setLoading(true); // Set loading to true before fetching
      getAdmins(currentPage);
      setLoading(false); // Set loading to false after fetching
    };

    fetchAdmin();
  }, [currentPage]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const deleteAdmin = async (id) => {
    let sweetAlertClass = new SweetAlertComponent();
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        sweetAlertClass.showSweetAlert(1, "", "Admin Deleted Successfully");
        getAdmins(currentPage);
      } else {
        const data = await response.json();
        sweetAlertClass.showSweetAlert(2, "", "Error Happened While Deleting Admin");
      }
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "", "Error Occurred While Deleting Admin");
    }
  };

  const handleNavigate = (id) => {
    navigate(`/dashboard/editadmin/${id}`);
  };

  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3>All Admin</h3>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        <li>
                          <Link to="/" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Dashboard</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <Link to="admin" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Admin</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <div className="text-tiny">All Aino Staff</div>
                        </li>
                      </ul>
                    </div>
                    <div className="wg-box">
                      {loading ? (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                          <img src={LoadingGif} alt="Loading All Data..." style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                          <p>Loading List of Admin Info...</p>
                        </div>
                      ) : (
                        <>
                          <div className="flex items-center justify-between gap10 flex-wrap">
                            <div className="wg-filter flex-grow"></div>
                            <Link className="tf-button style-1 w208" to="/dashboard/addadmin">
                              <FaPlus size={20} />
                              Add New Aino Staff
                            </Link>
                          </div>
                          <div className="wg-table table-all-user">
                            <ul className="table-title flex gap20 mb-14">
                              <li>
                                <div className="body-title">Full Name</div>
                              </li>
                              <li>
                                <div className="body-title">Email</div>
                              </li>
                              <li>
                                <div className="body-title">Roles</div>
                              </li>
                              <li>
                                <div className="body-title">Ref Code</div>
                              </li>
                              <li>
                                <div className="body-title">Action</div>
                              </li>
                            </ul>
                            <ul className="flex flex-column">
                              {admin?.map((admin, index) => (
                                <li key={admin._id} className="user-item gap14">
                                  <div className="body-text">{index + 1}</div>
                                  <div className="image">
                                    {admin.image ? <img src={admin.image} alt={`${admin.firstName} ${admin.lastName}`} className="" /> : <div>No Image</div>}
                                  </div>
                                  <div className="flex items-center justify-between gap20 flex-grow">
                                    <div className="name">
                                      <Link to="#" className="body-title-2"> {admin.firstName} {admin.lastName} </Link>
                                    </div>
                                    <div className="body-text">{admin.email}</div>
                                    { admin.hasOwnProperty('level') && <div className="body-text">{admin.level.name}</div> }
                                    <div className="body-text">{admin?.refCode} <span onClick={() => utility.copyToClipboard(admin?.refCode)}><FaCopy /></span></div>
                                    <div className="list-icon-function">
                                      <div className="item edit" onClick={() => handleNavigate(admin._id)}>
                                        <CiEdit color="green" />
                                      </div>
                                      <div className="item trash" onClick={() => deleteAdmin(admin._id)}>
                                        <FaRegTrashCan color="red" />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                              {admin?.length === 0 && (
                                <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                  <img src={NoAdminImage} alt="No Admin Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                                  <h2>No Admin found.</h2>
                                </li>
                              )}
                            </ul>
                          </div>
                          <div className="divider"></div>
                          <div className="flex items-center justify-between flex-wrap gap10">
                            <div className="wg-pagination-wrap"></div>
                            <ul className="wg-pagination">
                              <li>
                                <Link to="#" onClick={handlePreviousPage} style={{ textDecoration: 'none' }}>
                                  <FaChevronLeft />
                                </Link>
                              </li>
                              {[...Array(totalPages)].map((_, index) => (
                                <li key={index}>
                                  <Link to="#" onClick={() => setCurrentPage(index + 1)} style={{ textDecoration: 'none', fontWeight: currentPage === index + 1 ? 'bold' : 'normal' }}>
                                    {index + 1}
                                  </Link>
                                </li>
                              ))}
                              <li>
                                <Link to="#" onClick={handleNextPage} style={{ textDecoration: 'none' }} disabled={currentPage === totalPages}>
                                  <FaAngleRight />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">
                    Copyright © 2024 Aino Tech Global.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
