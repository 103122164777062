import React, { useState } from "react";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../component/AuthContext";
import { Link } from "react-router-dom";
import NoAdminImage from '../../images/logo/logo.png';
import { useNavigate } from "react-router-dom";
import SweetAlertComponent from "../../utils/alert";

const VerifyEmail = () => {
    const { error } = useAuth();
    const navigate = useNavigate();
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [apiError, setApiError] = useState(null); // Store API error if any
    const [isLoading, setIsLoading] = useState(false); // Track loading state

    const handleChange = (element, index) => {
        const value = element.target.value;
        if (/^[0-9]$/.test(value) || value === "") {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value !== "" && index < otp.length - 1) {
                document.getElementById(`otp-${index + 1}`).focus();
            }
        }
    };

    const verifyOtp = async (otpCode) => {
        let sweetAlertClass = new SweetAlertComponent();
        setIsLoading(true);
        try {
            // const OTPToken = localStorage.getItem("OTPToken");
            const OTPToken = JSON.parse(localStorage.getItem("OTPToken"));
            console.log(OTPToken);
            if (!OTPToken) {
                sweetAlertClass.showSweetAlert(2, "Error", "OTP token is missing. Please request a new OTP.");
            }
            const response = await fetch("https://aino-tech-server.onrender.com/api/v1/vendor/verify-otp", {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'x-vendor-token': `${OTPToken}`
                },
                body: JSON.stringify({ otp: otpCode }),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || "Verification failed. Please try again.");
            }
            sweetAlertClass.showSweetAlert(1, "Success", data.message);
            navigate("/merchantlogin"); // Redirect on success
            localStorage.removeItem('OTPToken');
        } catch (error) {
            // console.error("Error during OTP verification:", error);
            sweetAlertClass.showSweetAlert(2, "Error", error.message);
            setApiError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const otpCode = otp.join("");
        verifyOtp(otpCode);
    };

    return (
        <div className="container">
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex justify-content-center py-4">
                                <Link to="" className="logo d-flex align-items-center w-auto">
                                    <img src={NoAdminImage} alt="" className="large-image" />
                                </Link>
                            </div>

                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="pt-4 pb-2">
                                        <p className="text-center run">Enter the OTP sent to your email</p>
                                    </div>
                                    {Array.isArray(error) && error.length > 0 && (
                                        <div className="alert alert-danger">
                                            <ul>
                                                {error.map((err, index) => (
                                                    <li key={index}>{err.msg}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    {apiError && <div className="alert alert-danger">{apiError}</div>}
                                    <form onSubmit={onSubmit} className="row g-3 text-center">
                                        <div className="col-12 d-flex justify-content-center gap-2">
                                            {otp.map((value, index) => (
                                                <input
                                                    key={index}
                                                    id={`otp-${index}`}
                                                    type="text"
                                                    maxLength="1"
                                                    value={value}
                                                    onChange={(e) => handleChange(e, index)}
                                                    className="form-control otp-input"
                                                    style={{ width: "70px", height: "70px", fontSize: "2rem", textAlign: "center" }}
                                                />
                                            ))}
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
                                                {isLoading ? "Verifying..." : "Verify OTP"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="credits">Developed by <Link to="">Aino-Tech Global</Link> </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default VerifyEmail;
