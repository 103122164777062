import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaAngleRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { FaPlus, FaRegTrashCan } from "react-icons/fa6";
import { useAuth } from "../component/AuthContext";
import SweetAlertComponent from "../utils/alert";
import { Link, useNavigate } from "react-router-dom";
import NoAdminImage from '../images/avatar/category.png';


const SubCategories = () => {
    // State for search input value
    const navigate = useNavigate();
    const { subCategories } = useAuth();

    const [searchValue, setSearchValue] = useState("");

    // Function to handle search input change
    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const deleteCategory = async (id) => {
        let sweetAlertClass = new SweetAlertComponent();
        try {
            const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/category/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                sweetAlertClass.showSweetAlert(1, "", "Categories Deleted Successfully");
                window.location.reload();
            } else {
                const data = await response.json();
                sweetAlertClass.showSweetAlert(2, "", "Error Happened While Deleting Categories");
            }
        } catch (error) {
            sweetAlertClass.showSweetAlert(2, "", "Error Occurred While Deleting Categories");
        }
    };

    return (
        <div>
            <div id="wrapper">
                <div id="page" className="">
                    <div className="layout-wrap">
                        <div className="section-content-right">
                            <div className="main-content">
                                <div className="main-content-inner">
                                    <div className="main-content-wrap">
                                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                            <h3>All Sub Category</h3>
                                            <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                                                <li>
                                                    <Link to="/" style={{ textDecoration: "none" }}>
                                                        <div className="text-tiny">Dashboard</div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <FaAngleRight />
                                                </li>
                                                <li>
                                                    <Link to="#" style={{ textDecoration: "none" }}>
                                                        <div className="text-tiny">Sub Category</div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <FaAngleRight />
                                                </li>
                                                <li>
                                                    <div className="text-tiny">All Sub category</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="wg-box">
                                            <div className="flex items-center justify-between gap10 flex-wrap">
                                                <div className="wg-filter flex-grow">
                                                    
                                                </div>
                                                <Link className="tf-button style-1 w208" to="/dashboard/NewSubCategories" > <FaPlus size={20} />
                                                    New Sub Category
                                                </Link>
                                            </div>
                                            <div className="wg-table table-all-category">
                                                <ul className="table-title flex gap10 mb-14">
                                                    <li>
                                                        <div className="body-title">Sub Category Image </div>
                                                    </li>   
                                                    <li>
                                                        <div className="body-title">Category Name </div>
                                                    </li>
                                                    <li>
                                                        <div className="body-title">Sub Category</div>
                                                    </li>
                                                    <li>
                                                        <div className="body-title">Status</div>
                                                    </li>
                                                    
                                                </ul>
                                                <ul className="flex flex-column">
                                                    {subCategories.map((subCategory) => (
                                                        <li key={subCategory._id} className="product-item gap14">
                                                            <div className="flex items-center justify-between gap20 flex-grow">
                                                                <div className="image">
                                                                    {subCategory.img ? <img src={subCategory.img} style={{ width: '50px', height: '50px' }} alt={`${subCategory.name}`} className="" /> : <div>Sub Category Image</div>}
                                                                </div>
                                                                <div className="name">
                                                                    <Link to="#" className="body-title-2">
                                                                        {subCategory.parent.slug}
                                                                    </Link>
                                                                </div>
                                                                <div className="body-text">{subCategory.name}</div>
                                                                <div className="block-available">{subCategory.status}</div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                    {subCategories.length === 0 && (
                                                        <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                        <img src={NoAdminImage} alt="No Category Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                                                        <h2>No Sub Category Found </h2>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            <div className="divider"></div>
                                            <div className="flex items-center justify-between flex-wrap gap10">
                                            <div className="wg-pagination-wrap"></div>
                                                <ul className="wg-pagination">
                                                    <li>
                                                        <Link to="#" style={{ textDecoration: "none" }}>
                                                            <FaChevronLeft />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" style={{ textDecoration: "none" }}>
                                                            1
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" style={{ textDecoration: "none" }}>
                                                            <FaAngleRight />
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-page">
                                    <div className="body-text">
                                        Copyright © 2024 Aino Tech Global.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubCategories;
