import React, { useState, useEffect } from "react";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaAngleRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useAuth } from "../component/AuthContext";
import { IoEyeOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import SweetAlertComponent from "../utils/alert";
import { Link } from "react-router-dom";
import NoAdminImage from '../images/avatar/voucher.jpg';
import { Modal, Button } from "react-bootstrap";
import LoadingGif from '../images/avatar/aino_loading.gif';


const VoucherList = () => {
    // State for search input value
    const { voucher = [], getAllVoucher } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true); 
    const [totalPages, setTotalPages] = useState(1);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showProductModal, setShowProductModal] = useState(false);

    useEffect(() => {
        const fetchVouchers = async () => {
          setLoading(true); 
          await getAllVoucher(currentPage);
          setLoading(false); 
        };
    
        fetchVouchers();
      }, [currentPage]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
        }
    };
    
    const handlePreviousPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
    };

    const handleShowProductModal = (product) => {
        setSelectedProduct(product);
        setShowProductModal(true);
    };

    const handleCloseProductModal = () => {
        setShowProductModal(false);
        setSelectedProduct(null);
    };

    const convertExpirationTime = (expirationDate) => {
        const date = new Date(expirationDate);
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
        return date.toLocaleString('en-US', options);
    };

    return (
        <div>
            <div id="wrapper">
                <div id="page" className="">
                    <div className="layout-wrap">
                        <div className="section-content-right">
                            <div className="main-content">
                                <div className="main-content-inner">
                                    <div className="main-content-wrap">
                                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                            <h3>All Voucher Code List</h3>
                                            <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                                                <li>
                                                    <Link to="/" style={{ textDecoration: "none" }}>
                                                        <div className="text-tiny">Dashboard</div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <FaAngleRight />
                                                </li>
                                                <li>
                                                    <Link to="#" style={{ textDecoration: "none" }}>
                                                        <div className="text-tiny">Voucher Code</div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <FaAngleRight />
                                                </li>
                                                <li>
                                                    <div className="text-tiny">All Voucher Code</div>
                                                </li>
                                            </ul>
                                        </div>
                                        {loading ? (
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '150px' }}>
                                                <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                                                <p>All Vouchers Loading...</p>
                                            </div>
                                            ) : (
                                            <div className="wg-box">
                                                <div className="wg-table table-all-category">
                                                    <ul className="table-title flex gap10 mb-14">
                                                        <li><div className="body-title"> Voucher Code </div></li>
                                                        <li><div className="body-title"> Voucher Type </div></li>
                                                        <li><div className="body-title">Expiration Date </div></li>
                                                        <li><div className="body-title">Used Status</div></li>
                                                        <li> <div className="body-title">View More</div></li>
                                                    </ul>
                                                    <ul className="flex flex-column">
                                                        {voucher.map((vouchers , index) => (
                                                            <li key={vouchers} className="product-item gap14">
                                                                <div className="body-text">{(index + 1)}</div>
                                                                <div className="flex items-center justify-between gap20 flex-grow">
                                                                    <div className="body-text">{vouchers.code}</div>
                                                                    <div className="body-text">{vouchers.type}</div>
                                                                    <div className="body-text">{convertExpirationTime(vouchers.createdAt)}</div>
                                                                    <div className="body-text">{vouchers.isUsed ? "Used" : "Unused"}</div>
                                                                    <div className="list-icon-function">
                                                                        <div className="item eye" onClick={() => handleShowProductModal(vouchers)}>
                                                                            <IoEyeOutline color="blue"  />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                        {voucher.length === 0 && (
                                                            <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                                <img src={NoAdminImage} alt="No Vouchers Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover'}} />
                                                                <h2>No Voucher Code Found </h2>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="divider"></div>
                                                <div className="flex items-center justify-between flex-wrap gap10">
                                                <div className="wg-pagination-wrap"></div>
                                                    <ul className="wg-pagination">
                                                        <li>
                                                            <Link to="#" onClick={handlePreviousPage} className="pagination-prev" style={{ pointerEvents: currentPage === 1 ? 'none' : 'auto', opacity: currentPage === 1 ? 0.5 : 1 }}>
                                                            <FaChevronLeft />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <span>{currentPage}</span>
                                                        </li>
                                                        <li>
                                                            <Link to="#" onClick={handleNextPage} className="pagination-next" style={{ pointerEvents: currentPage === totalPages ? 'none' : 'auto', opacity: currentPage === totalPages ? 0.5 : 1 }}>
                                                            <FaChevronRight />
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="bottom-page">
                                    <div className="body-text">
                                        Copyright © 2024 Aino Tech Global.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Model for View More Details for Voucher Clicked */}
            <Modal show={showProductModal} onHide={handleCloseProductModal}>
                <Modal.Header closeButton>
                <Modal.Title>Voucher Code Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {selectedProduct && (
                    <div>
                        <h4>Code Name: {selectedProduct.code}</h4>
                        <p>Code ID: {selectedProduct._id}</p>
                        <p>Code Type: {selectedProduct.type}</p>
                        <p>Discount Percentage: {selectedProduct.discountPercentage}%</p>
                        <p>Code issued By : {selectedProduct.issuedBy.firstName} {selectedProduct.issuedBy.lastName}</p>
                        <p>Current Uses: {selectedProduct.currentUses}</p>
                        <p>Code Status: {selectedProduct.isUsed ? "Used" : "Unused" }</p>
                        <p>Date Created:{convertExpirationTime(selectedProduct.createdAt)}</p>
                    </div>
                )}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseProductModal}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default VoucherList;
