import React from "react";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../component/AuthContext";
import { Link } from "react-router-dom";
import NoAdminImage from '../images/logo/logo.png';

const InfluencerSignIn = () => {

    return (
        <div id="wrapper">
            <div id="page" class="">
                <div class="wrap-login-page sign-up">
                    <div class="flex-grow flex flex-column justify-center gap30">
                    <Link to="/" id="site-logo-inner">  <img src={NoAdminImage} alt="Aino Tech Logo" /> </Link>
                        <div class="login-box">
                            <div>
                                <h1 class="login-title">INFLUENCER SIGN UP</h1>
                                <div class="body-text">Enter Your Brand Name and Create your Account </div>
                            </div>
                            <form class="form-login flex flex-column gap24">
                                <fieldset class="email">
                                    <div class="body-title mb-10">Brand Email <span class="tf-color-1">*</span></div>
                                    <input class="flex-grow" type="email" placeholder="Enter your Brand email address" name="email" tabindex="0" value="" aria-required="true" required=""/>
                                </fieldset>
                                <fieldset class="email">
                                    <div class="body-title mb-10">Brand Name <span class="tf-color-1">*</span></div>
                                    <input class="flex-grow" type="email" placeholder="Enter your Brand Name" name="email" tabindex="0" value="" aria-required="true" required=""/>
                                </fieldset>
                                <fieldset class="password">
                                    <div class="body-title mb-10">Password <span class="tf-color-1">*</span></div>
                                    <input class="password-input" type="password" placeholder="Enter your password" name="password" tabindex="0" value="" aria-required="true" required=""/>
                                    <span class="show-pass">
                                        <i class="icon-eye view"></i>
                                        <i class="icon-eye-off hide"></i>
                                    </span>
                                </fieldset>
                                <h3> <Link to="/dashboard/login"> Login As a Aino Staff or Admin </Link></h3>
                                <Link to="influencerLogin" class="tf-button w-full">Login</Link>
                            </form>
                        </div>
                    </div>
                    <div class="text-tiny">Copyright © 2024 Aino Tech Global.</div>
                </div>
            </div>
        </div>
    );
};

export default InfluencerSignIn
