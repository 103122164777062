import React, { useState, useEffect } from "react";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaAngleRight, FaPlus, FaRegTrashCan } from "react-icons/fa6";
import { useAuth } from "../component/AuthContext";
import SweetAlertComponent from "../utils/alert";
import { Link, useNavigate } from "react-router-dom";
import NoAdminImage from '../images/avatar/advert.jpeg';
import LoadingGif from '../images/avatar/aino_loading.gif';

const Advert = () => {
  const navigate = useNavigate();
  const { sliders , getBannerList} = useAuth();
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchSliders = async () => {
      setLoading(true); 
      getBannerList();
      setLoading(false); 
    };

    fetchSliders();
  }, []);

  const deleteCategory = async (id) => {
    let sweetAlertClass = new SweetAlertComponent();
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/settings/delete-slider/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        sweetAlertClass.showSweetAlert(1, "", "Aino Advert Deleted Successfully");
        getBannerList();
      } else {
        const data = await response.json();
        sweetAlertClass.showSweetAlert(2, "", "Error Happened While Deleting Advert");
      }
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "", "Error Occurred While Deleting Advert");
    }
  };

  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3>All Aino Banner</h3>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        <li>
                          <Link to="/" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Dashboard</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <Link to="#" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Banner</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <div className="text-tiny">All Banner</div>
                        </li>
                      </ul>
                    </div>
                    <div className="wg-box">
                      <div className="flex items-center justify-between gap10 flex-wrap">
                        <div className="wg-filter flex-grow"></div>
                        <Link className="tf-button style-1 w208" to="/dashboard/addadvert">
                          <FaPlus size={20} />
                          Add Banner
                        </Link>
                      </div>
                      <div className="wg-table table-all-category">
                        {loading ? ( // Check if loading
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '120px' }}>
                            <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                            <p>Aino App Advert Loading ...</p>
                          </div>
                        ) : (
                          <>
                            <ul className="table-title flex gap10 mb-14">
                              <li>
                                <div className="body-title">Banner Image</div>
                              </li>
                              <li>
                                <div className="body-title">Banner Name</div>
                              </li>
                              <li>
                                <div className="body-title">Banner ID</div>
                              </li>
                              <li>
                                <div className="body-title">Action</div>
                              </li>
                            </ul>
                            <ul className="flex flex-column">
                              {sliders.map((category) => (
                                <li key={category} className="product-item gap14">
                                  <div className="flex items-center justify-between gap20 flex-grow">
                                    <div className="image">
                                      {category.imagePath ? (
                                        <img src={category.imagePath} style={{ width: '50px', height: '50px' }} alt={`${category.title}`} className="" />
                                      ) : (
                                        <div>Banner Image</div>
                                      )}
                                    </div>
                                    <div className="name">
                                      <Link to="javascript:void(0)" className="body-title-2">
                                        {category.title}
                                      </Link>
                                    </div>
                                    <div className="body-text">{category._id}</div>
                                    <div className="list-icon-function">
                                      <div className="item trash" onClick={() => deleteCategory(category._id)}>
                                        <FaRegTrashCan color="red" />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                              {sliders.length === 0 && (
                                <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                  <img src={NoAdminImage} alt="No Banner Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                                  <h2>No Advert Found</h2>
                                </li>
                              )}
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">
                    Copyright © 2024 Aino Tech Global.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advert;
