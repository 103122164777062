import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { FaAngleRight, FaChevronLeft } from "react-icons/fa6";
import { IoEyeOutline } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import NoAdminImage from '../images/avatar/voucher.jpg';
import LoadingGif from '../images/avatar/aino_loading.gif';
import SweetAlertComponent from "../utils/alert";
import Utlilty from "../utils/func";
import { FaChevronRight } from "react-icons/fa";

const OrderList = () => {
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrders] = useState([]);  // State to hold fetched orders
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);  // Loading state
  const navigate = useNavigate();
  const itemsPerPage = 20;
  const utility = new Utlilty();

  // Function to fetch total orders
  const fetchTotalOrders = async () => {
    const token = localStorage.getItem('token');
    const url = `https://aino-tech-server.onrender.com/api/v1/orders/total-orders`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch total orders count');
      }

      const data = await response.json();
      return data.totalOrders; 
    } catch (error) {
      console.error("Error fetching total orders:", error);
      return 0; // Default to 0 if there's an error
    }
  };

  // Fetch orders from the server
  const getAllOrders = async (page = 1, limit = itemsPerPage, filter = '') => {
    setLoading(true); 
    let sweetAlertClass = new SweetAlertComponent();
    const token = localStorage.getItem('token');
    const params = new URLSearchParams({
      page,
      limit,
      filter,
    });
    const url = `https://aino-tech-server.onrender.com/api/v1/orders/get-orders?${params.toString()}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch order details');
      }

      const data = await response.json();
      setOrders(data.orders); 
      setLoading(false);
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "Error", error.message);
      setLoading(false);
    }
  };

  // Use useEffect to trigger order fetching and total orders fetching
  useEffect(() => {
    const fetchData = async () => {
      const totalOrders = await fetchTotalOrders();
      await getAllOrders(1, itemsPerPage); // Fetch orders for the first page
      const totalPages = Math.ceil(totalOrders / itemsPerPage);
      setTotalPages(totalPages);
    };

    fetchData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      getAllOrders(newPage, itemsPerPage); 
    }
  };

  const handleNavigate = (id) => {
    navigate(`/dashboard/orderdetails/${id}`);
  };

  return (
    <div>
      <div id="wrapper">
        <div id="page">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3>Order List</h3>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        <li>
                          <Link to="/" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Dashboard</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <Link to="#" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Order</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <div className="text-tiny">Order List</div>
                        </li>
                      </ul>
                    </div>
                    <div className="wg-box">
                      <div className="wg-table table-all-category">
                        <ul className="table-title flex gap20 mb-14">
                          <li><div className="body-title">Product</div></li>
                          <li><div className="body-title">User</div></li>
                          <li><div className="body-title">Order ID</div></li>
                          <li><div className="body-title">Total Amount</div></li>
                          <li><div className="body-title">Quantity</div></li>
                          <li><div className="body-title">Payment</div></li>
                          <li><div className="body-title">Order Status</div></li>
                          <li><div className="body-title">Action</div></li>
                        </ul>

                        <ul className="flex flex-column">
                          {loading ? (
                            <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                              <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                              <p>All Orders Loading...</p>
                            </li>
                          ) : ( 
                            order.map((item, orderIndex) => {
                              const productItem = item.products[0]; // Get only the first product

                              return (
                                <li key={orderIndex} className="product-item gap14">
                                  <div className="body-text">{orderIndex + 1}</div>
                                  <div className="image no-bg">
                                    <img src={productItem.product.images[0] || NoAdminImage} alt={productItem.product.name} />
                                  </div>
                                  <div className="flex items-center justify-between gap20 flex-grow">
                                    <div className="name">
                                      <Link to="#" className="body-title-2">
                                        {productItem.product.name}
                                      </Link>
                                    </div>
                                    <div className="body-text">
                                      {item.user?.firstName || " "} {item.user?.lastName || " "}
                                    </div>
                                    <div className="body-text">{item._id}</div>
                                    <div className="body-text"> &#8358; {utility.formatPrice(item.totalAmount)} </div>
                                    <div className="body-text">{productItem.quantity} Pics</div>
                                    <div>
                                      <div className={item.isPaid ? "block-available" : "block-not-available"}>
                                        {item.isPaid ? "True" : "False"}
                                      </div>
                                    </div>
                                    <div>
                                      <div className={`block-available ${productItem.orderStatus === "pending" ? "pending" : "success"}`}>
                                        {productItem.orderStatus}
                                      </div>
                                    </div>
                                    <div className="list-icon-function">
                                      <div className="item eye" onClick={() => handleNavigate(item._id)}>
                                        <IoEyeOutline color="blue" />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          )}
                          {order.length === 0 && !loading && (
                            <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                              <img src={NoAdminImage} alt="No Order Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                              <h2>No Order Found</h2>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="divider"></div>
                      <div className="flex items-center justify-between flex-wrap gap10">
                        <div className="wg-pagination-wrap"></div>
                        <ul className="wg-pagination">
                          <li>
                            <Link to="#" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                              <FaChevronLeft />
                            </Link>
                          </li>
                          <li>{currentPage}</li>
                          <li>
                            <Link to="#" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                              <FaChevronRight />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
};

export default OrderList;
