import { Component } from 'react';

class Utility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalPages: 1 // Define totalPages if needed, or set it dynamically.
    };
  }

  limitText(text, wordLimit) {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  }

  formatPrice(price) {
    if (price == null || isNaN(price)) {
      return " "; // Handle cases where price is undefined, null, or not a number
    }
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  convertExpirationTime(createdAt) {
    const date = new Date(createdAt);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };
    return date.toLocaleString('en-US', options);
  }

  copyToClipboard(text) {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard:', text);
      })
      .catch((error) => {
        console.error('Failed to copy text:', error);
      });
  }

  // Upload image and get the URL
  uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await fetch('https://ainotechglobal.com/upload.php', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        return data.url; // Return the image URL
      } else {
        throw new Error('Failed to upload image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  convertCreatedTime(startDate) {
    const date = new Date(startDate);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };
    return date.toLocaleString('en-US', options);
  }

  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.setState((prevState) => ({ currentPage: prevState.currentPage - 1 }));
    }
  };

  handleNextPage = () => {
    if (this.state.currentPage < this.state.totalPages) {
      this.setState((prevState) => ({ currentPage: prevState.currentPage + 1 }));
    }
  };

  
}

export default Utility;
