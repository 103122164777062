import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import "../../index.css";
import { FaRegEye, FaRegEyeSlash, FaAngleRight } from "react-icons/fa6";
import { SlCloudUpload } from "react-icons/sl";
import { useAuth } from "../../merchant/component/AuthContext";
import SweetAlertComponent from "../../utils/alert";
import { useNavigate, Link } from 'react-router-dom';
import Utlilty from "../../utils/func";

const AddMerchantBank = () => {
    const [formData, setFormData] = useState({
        bankName: "",
        accountNumber: "",
        accountName: "",
    });

    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { setIsSaved } = useAuth();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        e.preventDefault();

        try {
            const vendorToken = localStorage.getItem('vendorToken');
            const response = await fetch("https://aino-tech-server.onrender.com/api/v1/vendor/update-bank-details", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'x-vendor-token': `${vendorToken}`,
                },
                body: JSON.stringify({
                    bankName: formData.bankName,
                    accountName: formData.accountName,
                    accountNumber: formData.accountNumber,
                }),
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            setIsSaved(true);
            sweetAlertClass.showSweetAlert(1, "", "Merchant Account Created Successfully");
            navigate('/merchant/merchantbank'); 
           
        } catch (error) {
            setError(error.message);
            console.error("There was an error!", error);
        }
    };

    return (
        <div>
            <div id="wrapper">
                <div id="page" className="">
                    <div className="layout-wrap">
                        <div className="section-content-right">
                            <div className="main-content">
                                <div className="main-content-inner">
                                    <div className="main-content-wrap">
                                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                            <h3>Create a New Merchant Account</h3>
                                            <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                                                <li>
                                                    <Link to="/merchant" style={{ textDecoration: "none" }}>
                                                        <div className="text-tiny">Dashboard</div>
                                                    </Link>
                                                </li>
                                                
                                            </ul>
                                        </div>
                                        <form className="form-add-new-user form-style-2" onSubmit={handleSubmit}>
                                            <div className="wg-box">
                                                <div className="left">
                                                    <div className="body-text">
                                                        Fill in the information to add a Merchant Bank Details
                                                    </div>
                                                </div>
                                                <div className="right flex-grow">
                                                    <fieldset className="name mb-24">
                                                        <div className="body-title mb-10">Bank Name</div>
                                                        <input className="flex-grow" type="text" placeholder="Bank Name" name="bankName" value={formData.bankName}
                                                            onChange={handleChange} aria-required="true" required />
                                                    </fieldset>
                                                    <fieldset className="name mb-24">
                                                        <div className="body-title mb-10">Account Name</div>
                                                        <input className="flex-grow" type="text" placeholder="Account Name" name="accountName" value={formData.accountName}
                                                            onChange={handleChange} aria-required="true" required />
                                                    </fieldset>
                                                    <fieldset className="name mb-24">
                                                        <div className="body-title mb-10">Account Number</div>
                                                        <input className="flex-grow" type="number" placeholder="Account Number" name="accountNumber" value={formData.accountNumber}
                                                            onChange={handleChange} aria-required="true" required />
                                                    </fieldset>
                                                </div>
                                            </div>

                                            <div className="bot">
                                                <button className="tf-button w180" type="submit">
                                                    Save
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="bottom-page">
                                    <div className="body-text">
                                        Copyright © 2024 Aino Tech Global. All rights reserved.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMerchantBank;
