import React, { useState } from "react";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import SweetAlertComponent from "../../utils/alert";
import NoAdminImage from '../../images/logo/logo.png';
import { RxEyeOpen } from "react-icons/rx";
import { GoEyeClosed } from "react-icons/go";

const MerchantSignUp = () => {
    const navigate = useNavigate();  // To redirect after successful signup
    const [formData, setFormData] = useState({
        storeName: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        description: "",
        // refereeId: " "
    });
    const [errors, setErrors] = useState([]); // For error handling
    const [loading, setLoading] = useState(false); // To handle loading state
    const [showPassword, setShowPassword] = useState(false); 

    // Handle form inputs change
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        e.preventDefault();
        setLoading(true);
        setErrors(null); // Clear previous error
        try {
            const response = await fetch('https://aino-tech-server.onrender.com/api/v1/vendor/sign-up', {
                method: 'POST',
                headers: {
                    
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (response.ok) {
                sweetAlertClass.showSweetAlert(1, "Success", data.message);
                localStorage.setItem('OTPToken', JSON.stringify(data.token));
                console.log(data.token);
                navigate("/verifyemail");
            } else {
                if (data.errors && data.errors.length > 0) {
                    setErrors(data.errors); 
                } else {
                    setErrors([{ msg: data.message }]); 
                }
            }
        } catch (error) {
            setErrors('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                            <div className="d-flex justify-content-center py-4">
                                <Link to="" className="logo d-flex align-items-center w-auto">
                                    <img src={NoAdminImage} alt="" className="large-image" />
                                </Link>
                            </div>

                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="pt-4 pb-2">
                                        <p className="text-center run-2">Kindly Provide Us with your Merchant Official details</p>
                                    </div>
                                    {errors && errors.length > 0 && (
                                        <div className="alert alert-danger">
                                            <ul>
                                                {errors?.map((error, index) => (
                                                    <li key={index}>{error.msg}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                    <form className="row g-3" onSubmit={handleSubmit}>
                                        <div className="col-12">
                                            <label htmlFor="storeName" className="form-label runs">Store Name</label>
                                            <input type="text" name="storeName" className="form-control" id="storeName" value={formData.storeName} onChange={handleChange} required />
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="email" className="form-label runs">Merchant Email</label>
                                            <input type="email" name="email" className="form-control" id="email" value={formData.email} onChange={handleChange} required />
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="firstName" className="form-label runs">First Name</label>
                                            <input type="text" name="firstName" className="form-control" id="firstName" value={formData.firstName} onChange={handleChange} required />
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="lastName" className="form-label runs">Last Name</label>
                                            <input type="text" name="lastName" className="form-control" id="lastName" value={formData.lastName} onChange={handleChange} required />
                                        </div>

                                        <div className="col-12 position-relative">
                                            <label htmlFor="password" className="form-label runs">Password</label>
                                            <input type={showPassword ? "text" : "password"} name="password" className="form-control" id="password" value={formData.password} onChange={handleChange}  required />
                                            <span className="password-toggle-icon" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '20px', top: '40px', cursor: 'pointer', fontSize: '1.9rem' }}>
                                                {showPassword ? <GoEyeClosed /> : <RxEyeOpen />}
                                            </span>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="description" className="form-label runs">Merchant Description</label>
                                            <textarea name="description" className="form-control" id="description" value={formData.description} onChange={handleChange} required />
                                        </div>

                                        {/* <div className="col-12">
                                            <label htmlFor="lastName" className="form-label runs">Referal Code (Optional)</label>
                                            <input type="text" name="refereeId" className="form-control" id="refereeId" value={formData.refereeId} onChange={handleChange} required />
                                        </div> */}

                                        <div className="col-12">
                                            <button className="runned" type="submit" disabled={loading}>
                                                {loading ? 'Creating Account...' : 'Create Account'}
                                            </button>
                                        </div>

                                        <div className="col-12">
                                            <p className="small mb-0 last-run">Already have an account?
                                                <Link to="/merchantlogin"> Log in</Link>
                                            </p>
                                        </div>
                                    </form>

                                </div>
                            </div>

                            <div className="credits">Developed by <Link to="">Aino-Tech Global</Link></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MerchantSignUp;
