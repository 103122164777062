import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
    const adminData = localStorage.getItem('adminData');
    const isAuthenticated = adminData ? JSON.parse(adminData).token : null;

    return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
