import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { FaAngleRight, FaChevronLeft, IoSearchOutline, FaPlus, FaRegTrashCan } from "react-icons/fa6";
import { useAuth } from "../component/AuthContext";
import { IoEyeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import LoadingGif from '../images/avatar/aino_loading.gif';
import NoAdminImage from '../images/avatar/role.png';
import Utlilty from "../utils/func";
import SweetAlertComponent from "../utils/alert";

const AllRoles = () => {
  const utility = new Utlilty();
  const { role, getRole } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRoles = async () => {
      setLoading(true);
      await getRole();
      setLoading(false);
    };

    fetchRoles();
  }, []);

  const deleteRoles = async (id) => {
    let sweetAlertClass = new SweetAlertComponent();
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        sweetAlertClass.showSweetAlert(1, "", "Role Deleted Successfully");
        getRole();
      } else {
        sweetAlertClass.showSweetAlert(2, "", "Error Happened While Deleting Role");
      }
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "", "Error Occurred While Deleting Role");
    }
  };

  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3>Role List</h3>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        <li>
                          <Link to="/" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Dashboard</div>
                          </Link>
                        </li>
                        <li><FaAngleRight /></li>
                        <li>
                          <Link to="#" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Roles</div>
                          </Link>
                        </li>
                        <li><FaAngleRight /></li>
                        <li><div className="text-tiny">Role List</div></li>
                      </ul>
                    </div>
                    <div className="wg-box">
                      <div className="flex items-center justify-between gap10 flex-wrap">
                        <div className="wg-filter flex-grow"></div>
                        <Link className="tf-button style-1 w208" to="/dashboard/createroles">
                          <FaPlus size={20} /> Add role
                        </Link>
                      </div>
                      <div className="wg-table table-all-roles">
                        {loading ? (
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <img src={LoadingGif} alt="Loading All Data..." style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                            <p>Loading List of Roles Info...</p>
                          </div>
                        ) : (
                          <ul className="flex flex-column">
                            {role.length > 0 ? (
                              role.map((role, index) => (
                                <li key={index} className="roles-item">
                                  <div className="body-text">{index + 1}</div>
                                  <div className="body-text">{role.name}</div>
                                  <div className="body-text">{utility.convertCreatedTime(role.createdAt)}</div>
                                  <div className="list-icon-function">
                                    <div className="item eye">
                                      <IoEyeOutline color="blue" />
                                    </div>
                                    <div className="item trash">
                                      <FaRegTrashCan color="red" onClick={() => deleteRoles(role._id)} />
                                    </div>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                <img src={NoAdminImage} alt="No Admin Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                                <h2>No Admin found.</h2>
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">
                    {" "}Copyright © 2024 Aino Tech Global.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRoles;
